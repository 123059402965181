import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import YouTube from "react-youtube";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useAppContext } from "../App.js";
import { Card, InpText, Tag } from "../components/utilityComps.js";
import {
  q_activity,
  q_activity_recent,
  q_astoksearch,
} from "../queries/queries.js";
import _ from "lodash";
import { ElementTag } from "../components/ShortComps.js";
import { InpDropdown, set_val_inp } from "../components/input.js";
import { getv, nils } from "../utils/utils.js";
import { Link } from "react-router-dom";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import { Logo, useLayoutContext } from "../components/Layout.js";
import { ActivityListTable } from "./ActivityPage.js";
import {
  faDiscord,
  faTwitch,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Search = () => {
  const [asset_type, set_asset_type] = useState("core");
  const [searchtxt, set_searchtxt] = useState(null);
  const [qohs] = useQueries([
    q_astoksearch({ asset_type, searchtxt, limit: 10 }),
  ]);
  const mats = useMemo(() => {
    return qohs.data?.result || [];
  }, [qohs.dataUpdatedAt]);

  return (
    <div className="xs:max-w-[90vw] lg:max-w-[45rem] w-full mx-auto relative">
      <div className="xs:fc-cc lg:fr-cc">
        <>
          <InpDropdown
            options={["core", "vault"]}
            setter={(v) => set_asset_type(v)}
            text_fn={(o) => {
              if (o == "core") return "By Core Names";
              else if (o == "vault") return "By Vaults";
              else return "Search By";
            }}
            txt={asset_type}
            is_active={(o) => o == asset_type}
            selection_cn="border border-acc0/60 bg-slate-800/60 px-2 xs:h-[1.5rem] lg:h-[2.5rem]"
            drop_cn=""
            active_cn=""
            option_cn="resp-p-2 bg-lig text-white"
          />

          <InpText
            {...{
              id: `search-fbike-core`,
              placeholder:
                asset_type == "core"
                  ? "Search Core"
                  : asset_type == "vault"
                  ? "Search Vault"
                  : "",
              inpprops: {
                className:
                  "resp-text-1 font-digi bg-slate-800/60 text-acc0 w-full",
              },
              contprops: {
                className:
                  "resp-text-1 font-digi bg-slate-800/60 text-acc0 w-full",
              },
              setter: (v) => {
                console.log("setter", v);
                if (nils(v)) v = null;
                set_searchtxt(v);
              },
            }}
          />
        </>
        {qohs.isLoading && <Loader01c size="s" />}
        {!_.isEmpty(mats) && (
          <>
            <div className="absolute top-[3rem] left-0 w-full max-h-[40vh] overflow-auto resp-p-1 bg-reg rounded-lg">
              {mats.map((h) => {
                return asset_type == "core" ? (
                  <>
                    <Link to={`/asset/core/${h.hid}`}>
                      <div className="resp-p-2 bg-dark rounded-md resp-my-2 cursor-pointer">
                        <div className="fr-sc resp-gap-2">
                          <div
                            className={twMerge(
                              `bg-${h.color} rounded-sm`,
                              "xs:w-[1.2rem] xs:h-[1.2rem] lg:w-[2.2rem] lg:h-[2.2rem]"
                            )}
                          ></div>
                          <div className="fc-ss font-digi flex-1">
                            <span className="resp-text--3">{h.hid}</span>
                            <span className="resp-text--2">{h.name}</span>
                          </div>
                          <div className="fc-ss font-digi resp-gap-2">
                            <span className="resp-text--3">
                              {`F${h.fno} / ${_.capitalize(h.type)}`}
                            </span>
                            <ElementTag
                              element={h.element}
                              className={"resp-text--3"}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                ) : asset_type == "vault" ? (
                  <>
                    <Link to={`/vault/${h.vault}`}>
                      <div className="resp-p-2 bg-dark rounded-md resp-my-2 cursor-pointer">
                        <div className="fc-ss resp-gap-2">
                          <p className="resp-text--1 text-acc0">
                            {h.vault_name}
                          </p>
                          <p className="resp-text--3">{h.vault}</p>
                        </div>
                      </div>
                    </Link>
                  </>
                ) : (
                  <></>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const RecentActivity = () => {
  const [qoactivity] = useQueries([q_activity_recent({ limit: 3 }, {})]);

  return (
    <Card className="max-w-[95vw] w-[80rem] bg-transparent mx-auto">
      <div
        className={twMerge(
          ""
          // "overflow-auto max-h-[35vh] shadow-sm shadow-acc0"
        )}
      >
        <ActivityListTable
          data={getv(qoactivity, "data.result")}
          qo={qoactivity}
        />
      </div>
    </Card>
  );
};

const ContactTag = ({ icon, txt, link }) => {
  return (
    <Link to={link} target="_blank">
      <div className="resp-p-2 fr-sc resp-gap-2 resp-text--1">
        <Tag className="fc-cc text-[1.5rem] xs:w-[1.5rem] xs:h-[1.5rem] md:w-[2rem] md:h-[2rem] lg:w-[4rem] lg:h-[4rem] rounded-full bg-dark shadow-md shadow-acc0 text-white">
          <FontAwesomeIcon className="resp-text-1" icon={icon} />
        </Tag>
        <span className="font-mon italic px-2">{txt}</span>
      </div>
    </Link>
  );
};

const Footer = () => {
  const laycon = useLayoutContext();

  return (
    <div className="bg-gradient-to-b from-lig to-transparent w-full">
      <section className="mx-auto w-[60rem] max-w-[95vw] resp-p-2">
        <p className="text-center my-2 font-digi resp-text-1">
          Sign in with{" "}
          <span
            onClick={() => laycon.set_loginpop(true)}
            className="cursor-pointer text-orange-300 underline underline-offset-4"
          >
            MetaMask
          </span>{" "}
          to explore and purchase all the available market listings.
        </p>
        <p className="fr-cc">
          {/* <div className="fc-cc rounded-full overflow-hidden max-w-[10rem]">
            <img src="https://i.imgur.com/lYds0vd.png" />
          </div> */}
          <div className="grid grid-cols-3 justify-center items-start p-2">
            <ContactTag
              link="https://www.twitch.tv/hale_end_ra"
              icon={faTwitch}
              txt="danshan11"
            />
            <ContactTag
              link="https://twitter.com/Spotonparts"
              icon={faTwitter}
              txt="@Spotonparts"
            />
            <ContactTag
              link={"https://discord.gg/j4k3NdY6St"}
              icon={faDiscord}
              txt={<>Discord</>}
            />
          </div>
        </p>
      </section>
    </div>
  );
};

function HomePage() {
  const { path, history } = useAppContext();
  const aucon = useAuthContext();
  useEffect(() => {
    if (aucon.auth == true) {
    }
  }, [aucon]);

  return (
    <div>
      <div className="h-[5rem]"></div>
      <>
        <div className="scale-[1.4] my-2">
          <Logo />
        </div>
        <Search />
        <RecentActivity />
        <Footer />
      </>
    </div>
  );
}

export default HomePage;
