import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "./AuthWrapper.js";
import {
  q_balance,
  q_bikeinfo,
  q_mm_weth_allowance,
  q_mm_weth_balance,
  q_token_prices,
  q_vaultinfo,
  qissuccess,
  useStepQuery,
  q_watchlist_get,
  q_mm_dez_balance,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { dec, getv, jparse, jstr, nils, toeth } from "../utils/utils.js";
import _ from "lodash";
import { set_state_ob } from "../components/input.js";
import { useMetaMaskContext } from "./MetaMaskWrapper.js";
import { contractAddress_list } from "../contracts/constants.js";
import { twMerge } from "tailwind-merge";
import { Tag, TokenIcon, ToolTip } from "../components/utilityComps.js";
import { mm_asset_signer } from "../contracts/contract_funcs.js";
import { useAppContext } from "../App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsd } from "@fortawesome/free-solid-svg-icons";

export const AccountContext = createContext({});
export const useAccountContext = () => useContext(AccountContext);

const def_acc_config = {
  mode: "all",
  vaulthconfs: {},
};

export default function AccountWrapper(props) {
  const aucon = useAuthContext();
  const { auth, vault } = aucon;
  const [qo_vaultinfo, qo_watchlist, qo_balance, qo_dezbalance] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_watchlist_get(
      { vault },
      {
        staleTime: 60 * 1e3,
        refetchInterval: 60 * 1e3,
      },
    ),
    q_mm_weth_balance(
      { wallet: vault },
      { staleTime: 30 * 1e3, refetchInterval: 30 * 1e3, enabled: auth },
    ),
    q_mm_dez_balance(
      { wallet: vault },
      {
        delay: 2 * 1e3,
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
        enabled: auth,
      },
    ),
  ]);

  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const acc_config_locpath = `acc_config-${vault}`;
  const [acc_config, set_acc_config] = useState(
    jparse(localStorage.getItem(acc_config_locpath), def_acc_config),
  );
  useEffect(() => {
    if (_.isEmpty(acc_config)) {
      let ob = jparse(localStorage.getItem(acc_config_locpath), def_acc_config);
      localStorage.setItem(acc_config_locpath, jstr(def_acc_config));
      set_acc_config(ob);
    } else {
      localStorage.setItem(acc_config_locpath, jstr(acc_config));
    }
  }, [acc_config]);
  const s_acc_config = (p, v) => {
    set_state_ob(acc_config, set_acc_config, p, v);
  };
  const g_acc_config = (p, def) => {
    return getv(acc_config, p) ?? def;
  };

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tok_ethusd = useMemo(
    () => getv(qo_tokpri, "data.result.ethusd"),
    [qo_tokpri.dataUpdatedAt],
  );

  const balance = useMemo(() => {
    if (!qissuccess(qo_balance)) return null;
    let bal = getv(qo_balance, "data.result.bal");
    return bal;
  }, [qo_balance.dataUpdatedAt]);

  const dezbalance = useMemo(() => {
    if (!qissuccess(qo_dezbalance)) return null;
    let bal = getv(qo_dezbalance, "data.result.bal");
    return bal;
  }, [qo_dezbalance.dataUpdatedAt]);

  // const market_allowance = useMemo(
  //   () => getv(qo_allowance, "data.result.alw", undefined),
  //   [qo_allowance.dataUpdatedAt],
  // );
  const market_allowance = useMemo(() => 0, []);
  const watchlist = useMemo(
    () => getv(qo_watchlist, "data.result", []),
    [qo_watchlist.dataUpdatedAt],
  );

  const accon = {
    vault,

    vdoc,

    qo_vaultinfo,

    acc_config,
    set_acc_config,
    s_acc_config,
    g_acc_config,

    qo_balance,
    balance,

    qo_dezbalance,
    dezbalance,

    // qo_allowance,
    market_allowance,

    tok_ethusd,
    qo_watchlist,
    watchlist,
  };
  return (
    <AccountContext.Provider value={accon}>
      {props.children}
    </AccountContext.Provider>
  );
}

export const AccountBalanceNav = () => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const { auth } = aucon;
  const { balance, market_allowance } = accon;

  /* const o = {
    auth,
    balance,
    market_allowance,
  }; */
  // console.log("auth", o);

  if (!auth) return <></>;

  const add_market_allowance = async () => {
    try {
      const con = await mm_asset_signer(null, 20, contractAddress_list.weth);
      await con.approve(contractAddress_list.dnamarket, toeth(0.2));
    } catch (err) {}
  };

  return (
    <div className="fr-sc resp-gap-2">
      <ToolTip
        message={"Market Allowance"}
        msg_cn="top-[3.5rem] resp-text--2 w-max"
      >
        <Tag
          onClick={add_market_allowance}
          className={twMerge(
            "rounded-full border border-yellow-300 text-yellow-300 bg-dark/80 fr-sc resp-gap-1",
            "resp-p-2",
          )}
        >
          <span className="resp-text--2">
            {nils(market_allowance) ? "--" : dec(market_allowance, 4)}
          </span>
        </Tag>
      </ToolTip>
      <ToolTip
        message={"Account Balance"}
        msg_cn="top-[3.5rem] resp-text--2 w-max"
      >
        <Tag
          className={twMerge(
            "rounded-full border border-acc0 text-acc0 bg-dark/80 fr-sc resp-gap-1",
            "resp-p-2",
          )}
        >
          <span className="resp-text--2">
            {nils(balance) ? "--" : dec(balance, 4)}
          </span>
        </Tag>
      </ToolTip>
    </div>
  );
};

export const AccountBalanceTag = () => {
  const appcon = useAppContext();
  const acc = useAccountContext() || {};
  const { s_acc_config, g_acc_config = () => {} } = acc;
  const { qo_balance, qo_dezbalance } = acc;

  const baleth = useMemo(() => {
    return acc?.balance;
  }, [acc]);
  const baldez = useMemo(() => {
    return acc?.dezbalance;
  }, [acc]);

  const balusd = useMemo(() => {
    let ethusd = getv(appcon.tokmap, "ethusd");
    return baleth * ethusd;
  }, [baleth, appcon.tokmap]);

  const baldezusd = useMemo(() => {
    let dezusd = getv(appcon.tokmap, "dezusd");
    return baldez * dezusd;
  }, [baldez, appcon.tokmap]);

  const viewbalance = g_acc_config("viewbalance", true) ?? false;

  return (
    <div
      onClick={() => {
        // s_acc_config("viewbalance", !viewbalance);
      }}
      onDoubleClick={() => {
        qo_dezbalance.refetch();
        qo_balance.refetch();
      }}
      className="cursor-pointer"
    >
      <div className="resp-px-4 py-1 rounded-full fr-cc resp-gap-2 bg-lig resp-text-0">
        {/* <span className="bg-dark rounded-md xs:h-[1.5rem] xs:w-[0.1rem]  lg:h-[2rem] lg:w-[0.3rem]"></span> */}

        <div className="fc-cc resp-gap-1">
          <div className="fr-cc resp-py-0 resp-gap-1">
            <TokenIcon size="xs" token={"WETH"} />{" "}
            {viewbalance == true ? (
              <span className="font-mon resp-text--1">{dec(baleth, 4)}</span>
            ) : (
              <span className="font-mon resp-text--1">{"***"}</span>
            )}
          </div>
          <div className="fr-cc resp-py-0 resp-gap-1">
            <FontAwesomeIcon className="resp-text--1" icon={faUsd} />
            {viewbalance == true ? (
              <span className="font-mon resp-text--1">{dec(balusd, 2)}</span>
            ) : (
              <span className="font-mon resp-text--1">{"***"}</span>
            )}
          </div>
        </div>

        <span className="bg-dark/40 rounded-md xs:h-[1.5rem] xs:w-[0.1rem]  lg:h-[2rem] lg:w-[0.3rem]"></span>

        <div className="fc-cc resp-gap-1">
          <div className="fr-cc resp-py-0 resp-gap-1">
            <TokenIcon size="xs" token={"DEZ"} />
            {viewbalance == true ? (
              <span className="font-mon resp-text--1">{dec(baldez, 0)}</span>
            ) : (
              <span className="font-mon resp-text--1">{"***"}</span>
            )}
          </div>
          <div className="fr-cc resp-py-0 resp-gap-1">
            <FontAwesomeIcon className="resp-text--1" icon={faUsd} />
            {viewbalance == true ? (
              <span className="font-mon resp-text--1">{dec(baldezusd, 2)}</span>
            ) : (
              <span className="font-mon resp-text--1">{"***"}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
