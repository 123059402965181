import React from "react";
import { Tag } from "../components/utilityComps.js";
import { jstr } from "./utils.js";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      err_msg: error.message,
      err_whole: error,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.err_comp) {
        return <>{this.props.err_comp}</>;
      } else {
        return (
          <>
            <p className="text-red-500 resp-text--1">{this.state.err_msg}</p>
            <div className="h-[1rem]"></div>
            {/*
            <pre className="text-red-500 resp-text--2 font-mono">
              {this.state.err_whole?.stack.toString()}
            </pre>
            */}
          </>
        );
      }
    }
    return this.props.children;
  }
}
export const ErrTag = () => <Tag className={"bg-red-500"}>err</Tag>;
