import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  q_activity,
  qiserr,
  qissuccess,
} from "../queries/queries.js";
import { InpAutoWrap } from "../components/input.js";
import _, { trim } from "lodash";
import { Loader01c } from "../components/anims.js";
import {
  base64_to_json,
  dec,
  getv,
  iso_format,
  json_to_base64,
  jstr,
  nils,
  trim_n,
} from "../utils/utils.js";
import { tablecn } from "../utils/cn_map.js";
import { gen_valob_from_filters } from "../utils/filt.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faChevronCircleRight,
  faToggleOff,
  faToggleOn,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Img, Tag } from "../components/utilityComps.js";
import { polychainimg } from "../utils/links";
import { Link } from "react-router-dom";
import { tokdecn, useAppContext } from "../App.js";
import { twMerge } from "tailwind-merge";
import { MiniElementTag } from "../components/ShortComps.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";

const ActivityContext = React.createContext();
const useActivityContext = () => React.useContext(ActivityContext);

const FiltSection = () => {
  const acon = useActivityContext();
  const { filt, set_filt, onlyvault, set_onlyvault } = acon;
  const inpargs = { filters: filt, set_filters: set_filt, fkey: "activity" };

  return (
    <div>
      <div className="fc-cc">
        <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
        <div className="w-full fr-sc max-w-[60rem] mx-auto">
          {/* <div className="flex-1"></div> */}
          <Tag
            onClick={() => {
              set_onlyvault(!(onlyvault == true));
            }}
            className={twMerge("")}
          >
            {onlyvault === true ? (
              <FontAwesomeIcon className="text-acc0" icon={faToggleOn} />
            ) : (
              <FontAwesomeIcon icon={faToggleOff} />
            )}
            {" My Vault Only"}
          </Tag>
        </div>
      </div>
    </div>
  );
};

const HoverPop = ({
  cont_className = "",
  hover_className = "",
  trigger = <></>,
  children = <></>,
  redirect = null,
}) => {
  const [hover, set_hover] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current) return;
    const eventListener = () => {
      ref.current.addEventListener("mouseenter", () => {
        set_hover(true);
      });
      ref.current.addEventListener("mouseleave", () => {
        set_hover(false);
      });
    };
    eventListener();
    return () => {};
  });

  const handle_redirect = () => {
    if (!nils(redirect)) window.open(redirect, "_blank");
  };

  return (
    <div
      onClick={handle_redirect}
      ref={ref}
      className={twMerge(
        "relative",
        !nils(redirect) ? "cursor-pointer" : null,
        cont_className,
      )}
    >
      <>{trigger}</>
      {hover && (
        <div className={twMerge("absolute bottom-0 left-0", hover_className)}>
          <>{children}</>
        </div>
      )}
    </div>
  );
};

export const ActivityListTable = ({ data, qo, hide_asset = false }) => {
  const appcon = useAppContext();
  const { tokmap } = appcon;
  // const acon = useActivityContext();
  // const { qoactivity } = acon;
  const [orders, vmap, infomap, mid_status] = useMemo(() => {
    // if (!qissuccess(qoactivity)) return [null, null, null];
    // let d = getv(qoactivity, "data.result");
    let d = data || {};
    return [d.orders, d.vmap, d.infomap, d.mid_status];
  }, [jstr(data), qo?.dataUpdatedAt]);
  return (
    <div className="w-full">
      {_.isEmpty(orders) ? (
        <>
          <p className="text-yellow-300 text-center">No Recent Activity Here</p>
        </>
      ) : (
        <table className={twMerge(tablecn.table, "w-full resp-text--2")}>
          <thead>
            <tr className="thintdrowp4 w-full text-acc0/40">
              <td>Type</td>
              {hide_asset !== true ? <td>Asset</td> : null}
              <td>Cost</td>
              <td>Transfer</td>
              <td>Date</td>
            </tr>
          </thead>
          <tbody>
            {orders?.map((o, idx) => {
              let k = `${o.asset_type}:${o.token_id}`;
              let i = getv(infomap, k);

              let from = o.from && o.from.toLowerCase();
              let to = o.to && o.to.toLowerCase();

              let status = o.type.includes("created") && mid_status[o.marketid];
              let txlink =
                o.type == "transfer" || o.type.includes("executed")
                  ? polytxnidlink(o.id)
                  : null;

              return (
                <tr className="thintdrowp4 resp-text--1" key={k}>
                  <td>
                    <div className="fc-cc resp-text--1">
                      {nils(o.type) ? null : o.type == "transfer" ? (
                        <span>Transfer</span>
                      ) : o.type.includes("executed") ? (
                        <span>Sale</span>
                      ) : o.type.includes("created") ? (
                        <>
                          <span>{_.upperCase(o.mtype)}</span>
                          {status && (
                            <span
                              className={twMerge(
                                "resp-text--3",
                                status == "expired"
                                  ? "text-red-300"
                                  : status == "accepted"
                                    ? "text-green-300"
                                    : status == "open"
                                      ? "text-blue-300"
                                      : "text-slate-300",
                              )}
                            >
                              {_.capitalize(status)}
                            </span>
                          )}
                        </>
                      ) : null}
                    </div>
                  </td>
                  {hide_asset !== true ? (
                    <td>
                      <>
                        <HoverPop
                          redirect={
                            o.asset_type == "core"
                              ? `/asset/core/${o.token_id}`
                              : o.asset_type == "skin"
                                ? `/asset/skin/${o.token_id}`
                                : null
                          }
                          cont_className="w-full"
                          trigger={
                            <div className="fc-ss ">
                              <div className="fr-sc resp-gap-1 w-full my-1 mx-3">
                                <span className="text-acc0">#{o.token_id}</span>
                                <div className="flex-1"></div>
                                <span>{_.capitalize(o.asset_type)}</span>
                              </div>
                              <div className="fr-sc w-full">
                                {nils(i) ? null : o.asset_type == "core" ? (
                                  <span>{trim_n(i.name, 20)}</span>
                                ) : o.asset_type == "skin" ? (
                                  <span>{trim_n(i.skin, 20)}</span>
                                ) : null}
                              </div>
                            </div>
                          }
                          hover_className={twMerge(" ", "top-[2.5rem] z-[10]")}
                        >
                          {!nils(i) && (
                            <div className="min-w-[25rem]">
                              <div className="grid grid-cols-12 text-lig w-full">
                                <div className=" col-span-5"></div>
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                              </div>
                              <div className="fc-ss bg-lig text-white resp-p-4 rounded-md shadow-md resp-gap-1 resp-text--2">
                                {o.asset_type == "core" ? (
                                  <>
                                    <div className="fr-cc w-full">
                                      <p className="font-digi resp-text--2 text-white">
                                        Core#{i.hid}
                                      </p>
                                    </div>
                                    <div className="fr-cc w-full">
                                      <p className="font-digi resp-text--1 text-acc0">
                                        {i.name}
                                      </p>
                                    </div>
                                    <table className="mx-auto">
                                      <tbody>
                                        {[
                                          ["Fno", `F${i.fno}`],
                                          ["Type", _.capitalize(i.type)],
                                          [
                                            "Element",
                                            <div className="fr-sc">
                                              <MiniElementTag
                                                element={i.element}
                                              />
                                              <span>
                                                {_.capitalize(i.element)}
                                              </span>
                                            </div>,
                                          ],
                                          [
                                            "Stats",
                                            <div className="fr-sc">
                                              <span>
                                                {getv(i, "career.races_n")}{" "}
                                                Races
                                              </span>
                                              <span>
                                                {", "}
                                                {dec(
                                                  getv(i, "career.win_p") * 100,
                                                  2,
                                                )}
                                                {"% "}
                                                Win
                                              </span>
                                            </div>,
                                          ],
                                        ].map(([a, b]) => {
                                          return (
                                            <tr className="thintdrow">
                                              <td className="text-slate-400">
                                                {a}
                                              </td>
                                              <td className="text-white">
                                                {b}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                ) : o.asset_type == "skin" ? (
                                  <>
                                    <>
                                      <div className="fr-cc w-full">
                                        <p className="font-digi resp-text--2 text-white">
                                          Skin#{i.skinid}
                                        </p>
                                      </div>
                                      <div className="fr-cc w-full">
                                        <p className="font-digi resp-text--1 text-acc0">
                                          {i.skin}
                                        </p>
                                      </div>
                                      <table className="mx-auto">
                                        <tbody>
                                          {[
                                            ["Rarity", _.capitalize(i.rarity)],
                                          ].map(([a, b]) => {
                                            return (
                                              <tr className="thintdrow">
                                                <td className="text-slate-400">
                                                  {a}
                                                </td>
                                                <td className="text-white">
                                                  {b}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </HoverPop>
                      </>
                    </td>
                  ) : null}
                  <td>
                    <div className="fr-sc my-1 mx-3">
                      <div className="flex-1"></div>
                      {nils(o.amt) ? (
                        <span className="text-slate-300">--</span>
                      ) : (
                        <>
                          <span className="resp-text-1 text-acc0">
                            {o.amt.toString()}
                          </span>
                          <span className="resp-text--3 text-acc0">
                            {o.token}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="fr-sc my-1 mx-3">
                      <span className="resp-text-1">x{o.qty}</span>
                      <div className="flex-1 min-w-[1rem]"></div>
                      {!nils(o.amt) && (
                        <>
                          <span>
                            $
                            {(
                              parseFloat(o.amt) *
                              parseFloat(
                                getv(
                                  tokmap,
                                  o.token == "WETH" ? "ethusd" : "dezusd",
                                ) ?? 0,
                              )
                            ).toFixed(2)}
                          </span>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <HoverPop
                      trigger={
                        <div className="fr-sc resp-gap-2 p-2">
                          <div className="xs:w-[0.6rem] lg:w-[1.4rem] ">
                            {!nils(txlink) ? (
                              <Link target={"_blank"} to={polytxnidlink(o.id)}>
                                <div className="xs:w-[0.6rem] lg:w-[1.4rem]">
                                  <Img img={polychainimg} />
                                </div>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="fr-sc resp-gap-2 flex-1 resp-text--3">
                            <div className="fc-ss">
                              <span className="">{trim_n(vmap[from], 10)}</span>
                              <span>{trim_n(from, 10)}</span>
                            </div>
                            <span>
                              <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                            <div className="fc-ss">
                              <span>{trim_n(vmap[to], 10)}</span>
                              <span>{trim_n(to, 10)}</span>
                            </div>
                          </div>
                        </div>
                      }
                      cont_className=""
                      hover_className={twMerge(" ", "top-[2.5rem] z-[10]")}
                    >
                      <div className="grid grid-cols-12 text-lig w-full">
                        <div className=" col-span-5"></div>
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </div>
                      <div className="fc-ss bg-lig text-white resp-p-4 rounded-md shadow-md resp-gap-1">
                        <>
                          {nils(to) ? (
                            <span>Transacted By</span>
                          ) : (
                            <span>FROM</span>
                          )}
                          <span className="resp-text--1 text-acc0">
                            {vmap[from]}
                          </span>
                          <span className="resp-text--2 text-purple-300">
                            {from}
                          </span>
                        </>
                        {!nils(to) && (
                          <>
                            <div className="fr-cc w-full resp-gap-1 my-2">
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="resp-text-1"
                              />
                            </div>
                            <span>TO</span>
                            <span className="resp-text--1 text-acc0">
                              {vmap[to]}
                            </span>
                            <span className="resp-text--2 text-purple-300">
                              {to}
                            </span>
                          </>
                        )}
                      </div>
                    </HoverPop>
                  </td>
                  <td>
                    <span>{iso_format(o.date)}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const ActivityPage = () => {
  const accon = useAccountContext();
  const { vault } = accon;

  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    return f;
  }, [jstr(psearch)]);

  const [filt, set_filt] = useState({
    type: {
      type: "options",
      path: "type",
      cfilter: true,
      label: "type",
      show_label: false,
      options: ["sales", "offers", "listings", "transfers"],
      filter_exception: [],
      vals: _.isEmpty(basefilt.type) ? ["sales"] : basefilt.type,
      txt_fn: (o) => _.capitalize(o),
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `font-digi resp-text-0 bg-dark border border-reg text-white`,
      color_fn: (o) => "",
      active_cn: (active, op) => {
        if (active) return `bg-white shadow-sm shadow-white text-dark`;
      },
    },
  });
  const [onlyvault, set_onlyvault] = useState(false);
  const valfilt = useMemo(() => {
    return gen_valob_from_filters(filt);
  }, [jstr(filt)]);
  const [qoactivity] = useQueries([
    q_activity(
      {
        filt: {
          ...valfilt,
          ...(onlyvault == true ? { vault } : {}),
        },
      },
      {},
    ),
  ]);

  useEffect(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    rem.onlyvault = onlyvault == true ? "true" : "false";
    upd_psearch(rem);
  }, [jstr(valfilt), onlyvault]);

  const acon = {
    filt,
    set_filt,
    qoactivity,
    onlyvault,
    set_onlyvault,
  };
  return (
    <>
      <ActivityContext.Provider value={acon}>
        <div className="h-page">
          <div className="mx-auto w-[80rem] max-w-[95vw]">
            <div className="h-[5rem]"></div>
            <FiltSection />
            <div className="h-[1rem]"></div>
            {qoactivity.isLoading ? (
              <Loader01c />
            ) : qiserr(qoactivity) ? (
              <p className="text-center text-red-300 resp-text-1">
                {qiserr(qoactivity)}
              </p>
            ) : qissuccess(qoactivity) ? (
              <ActivityListTable
                data={getv(qoactivity, "data.result")}
                qo={qoactivity}
              />
            ) : null}
            <div className="h-[5rem]"></div>
          </div>
        </div>
      </ActivityContext.Provider>
    </>
  );
};
