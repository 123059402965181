import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { Tag } from "../components/utilityComps.js";
import { createContext } from "react";
import { useContext } from "react";
import { useAppContext } from "../App.js";
import _ from "lodash";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import {
  q_auth_get_nonce,
  q_auth_verify_nonce,
  q_auth_verify_token,
} from "../queries/queries.js";
import { PopUp } from "../components/popup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MetaMaskContext = createContext({});
export const useMetaMaskContext = () => useContext(MetaMaskContext);

export const token_locpath = "jwt_auth_token";
export const tokenacc_locpath = "jwt_auth_account";
export const tokenaumode_locpath = "jwt_auth_aumode";
export const tokenauth_locpath = "jwt_auth_auth";

export const page_bypass_fn = (path) => {
  if (path.startsWith("/")) return true;
  if (path.startsWith("/race/")) return true;
  if (path.startsWith("/arcaderace")) return true;
  if (path.startsWith("/bike/")) return true;
  if (path.startsWith("/bikes")) return true;
  return false;
};

const get_token = () => {
  let token = localStorage.getItem(token_locpath);
  return token;
};

const gen_token = async () => {
  let mm = window.ethereum;
  let account = mm.selectedAddress;
  let vault = account.toLowerCase();

  let non = await q_auth_get_nonce({ vault });
  let nonce = getv(non, "result.nonce");
  if (nils(nonce)) throw new Error("couldn't fetch nonce");

  let tosign = nonce;
  let signed_data = await mm.request({
    method: "personal_sign",
    params: [tosign, account],
  });

  let ref = localStorage.getItem("ref");
  let resptok = await q_auth_verify_nonce({ signed_data, vault, ref });
  if (resptok?.status == "error") throw new Error(resptok.err);

  let token = getv(resptok, "result.token");
  localStorage.setItem(token_locpath, token);
  localStorage.setItem(tokenacc_locpath, account);
  localStorage.setItem(tokenaumode_locpath, "wallet");
  return token;
};

const request_metamask_login = async () => {
  let mm = window.ethereum;
  let resp = await mm.request({ method: "eth_requestAccounts" });
  let acc = getv(resp, 0);
  return acc;
};

const relogin = async () => {
  let mm = window.ethereum;
  await mm.request({
    method: "wallet_requestPermissions",
    params: [{ eth_accounts: {} }],
  });
};

const verify_token = async () => {
  let token = localStorage.getItem("jwt_auth_token");
  let vault = window.ethereum.selectedAddress?.toLowerCase();
  let resp = await q_auth_verify_token({ vault, token });
  if (resp.err) throw new Error(resp.err);
  return resp.result;
};

export const MetaMaskWrapper = (props) => {
  const { path } = useAppContext();

  const mm = window.ethereum;
  const [mm_connected, set_mm_connected] = useState(undefined);

  const try_mm_connect = async () => {
    await cdelay(500);
    let conn = nils(window.ethereum) ? false : true;
    set_mm_connected(conn);
    return conn;
  };

  useEffect(() => {
    const rec_fn = async () => {
      // console.log("init rec_fn");
      let aumode = localStorage.getItem(tokenaumode_locpath);
      if (aumode == "thirdweb") return;
      if (mm_connected) return;
      let valid = await try_mm_connect();
      // console.log("mm_connected", valid);
      if (!valid) {
        await cdelay(2 * 1e3);
        rec_fn();
      }
    };
    if (!mm_connected) rec_fn();
  }, [nils(window.ethereum), mm_connected]);

  const account = window.ethereum?.selectedAddress;

  const vault = useMemo(() => {
    if (nils(account)) return null;
    return account.toLowerCase();
  }, [account]);

  const mmcon = {
    mm,
    mm_connected,
    try_mm_connect,

    request_metamask_login,
    gen_token,
    get_token,
    verify_token,
    relogin,

    account,
    vault,
  };

  const bypass = useMemo(() => {
    return page_bypass_fn(path);
  }, [path]);

  const show = bypass || mm_connected === true;

  return (
    <>
      <MetaMaskContext.Provider value={mmcon}>
        {show == true && <>{props.children}</>}
      </MetaMaskContext.Provider>
    </>
  );
};

export const InstallMM = () => {
  const { try_mm_connect } = useMetaMaskContext();
  return (
    <div className="xs:w-[20rem] lg:min-w-[30rem]">
      <div class="fr-cc ">
        <p
          onClick={() => {
            try_mm_connect();
          }}
          className="resp-text-1  p-2 text-acc0 underline text-center cursor-pointer"
        >
          Force Connect MetaMask
        </p>
      </div>
      <hr />
      <p className="resp-text-1 text-center">Please Install MetaMask</p>
      <a
        target="_blank"
        href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
      >
        <div className="fr-cc bg-white rounded-md resp-p-2 resp-gap-2 w-max mx-auto">
          <img
            className="h-[4rem]"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
            alt=""
          />
          <img
            className="h-[4rem]"
            src="https://storage.googleapis.com/web-dev-uploads/image/WlD8wC6g8khYWPJUsQceQkhXSlv1/YT2Grfi9vEBa2wAPzhWa.png"
          />
        </div>
      </a>
    </div>
  );
};
