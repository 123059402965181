import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppContext } from "../App.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { useQueries } from "react-query";
import {
  q_astoktxns_relayback,
  q_vaubidslist,
  q_vaultinfo,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useMask } from "@react-three/drei";
import {
  cdelay,
  copy_clip,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nils,
} from "../utils/utils.js";
import {
  execute_bid,
  get_dnamarket_conaddr,
  get_dnamarket_k,
} from "./AssetPage.js";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { tablecn } from "../utils/cn_map.js";
import { Card, Tag, ToolTip } from "../components/utilityComps.js";
import {
  asset_types_map,
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MiniElementTag, MiniGenderTag } from "../components/ShortComps.js";
import { Link } from "react-router-dom";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import DNAMarket from "../contracts/DNAMarket/DNAMarket.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { polygon } from "thirdweb/chains";

const BidsContext = createContext();
const useBidsContext = () => useContext(BidsContext);

const DeleteBidBtn = ({ b }) => {
  const aucon = useAuthContext();
  const { aumode, auth, vault } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, thirdweb_client, active_account } = t3con;

  const bcon = useBidsContext();
  const { assetsinfo, vmap } = bcon;
  const { marketid, token } = b;

  const [pop, set_pop] = useState(false);
  const [popdata, set_popdata] = useState({});
  const closepopup = () => {
    set_pop(false);
    set_popdata({});
  };

  const [loading, set_loading] = useState(false);

  const delete_bid = async (b) => {
    let { marketid, token } = b;
    const conaddr = await get_dnamarket_conaddr(token);
    const conk = get_dnamarket_k(token);

    const con =
      aumode == "thirdweb"
        ? await DNAMarket.get_contract({
            contractAddress: conaddr,
            k: conk,
            nosigner: true,
            rpc: polygon.rpc,
          })
        : await DNAMarket.get_contract({ contractAddress: conaddr, k: conk });

    const assetcon =
      aumode == "thirdweb"
        ? await t3_asset_signer(b.asset_type)
        : await mm_asset_signer(b.asset_type);

    if (!con || nils(marketid)) return;
    console.log("delete_bid", marketid);

    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(
        conk,
        "deleteMarket",
        [marketid],
        "txn",
        true,
        { active_account },
      );
      await cdelay(2000);
    } else {
      tx = await con.deleteMarket(marketid);
      await cdelay(2000);
    }
    return tx.hash;
  };

  const auto_delete_bid = async (b) => {
    try {
      set_popdata({
        ...popdata,
        loading: true,
        msg: "Preparing to delete bid",
        err: null,
      });

      console.log("popdata", popdata);
      console.log("asty", asty);

      let hash = await delete_bid(b);
      if (!nils(hash)) {
        set_popdata({
          ...popdata,
          msg: "Transaction Sent... please wait few minutes for it to show up",
          err: null,
        });
        await q_astoktxns_relayback({
          hash,
          date: iso(),
        }).queryFn();
        await cdelay(5000);

        setTimeout(() => {
          closepopup();
        }, 300);
      }
    } catch (err) {
      set_popdata({
        ...popdata,
        loading: true,
        msg: null,
        err: !nils(err.reason) ? err.reason : err.message,
      });
      await cdelay(5000);
    }
    set_popdata({ ...popdata, loading: false });
  };

  const asty = asset_types_map[b.asset_type];
  const asinf = assetsinfo[`${b.asset_type}:${b.token_id}`];

  return (
    <>
      <Tag onClick={() => set_pop(true)} className={"bg-red-400 -skew-x-12"}>
        Delete
      </Tag>

      <PopUp openstate={pop} overlayclose={false} onClick={closepopup}>
        <Card className={"resp-text--2 max-w-[90vw] w-[50rem]"}>
          <div className="fr-sc">
            <div className="flex-1"></div>
            <PopupCloseBtn closepopup={closepopup} />
          </div>

          <div className="fr-sc my-2 resp-text--2">
            <div className="w-max">
              {popdata.loading && <Loader01c size="s" />}
            </div>
            {popdata.msg && (
              <div className="flex-1 p-2 text-green-300">{popdata.msg}</div>
            )}
            {popdata.err && (
              <div className="flex-1 p-2 text-red-300">{popdata.err}</div>
            )}
          </div>

          <Card className={"bg-dark w-full"}>
            <p>
              <span>{`MarketID: `}</span>
              <span className="text-acc0">{marketid}</span>
            </p>
            <p>
              <span>{`Asset Type: `}</span>
              <span className="text-acc0">{`${asty.name}`}</span>
            </p>
            <p>
              <span>{`TokenID: `}</span>
              <span className="text-acc0">{`${b.token_id}`}</span>
            </p>
            <p>
              <span>{`Info: `}</span>
              <span className="text-acc0">{`${asinf.name}`}</span>
            </p>
          </Card>

          <div className="fr-cc">
            {popdata.loading ? (
              <Tag className="text-red-300 fr-sc resp-gap-2">
                <Loader01c size="s" />
                <span>Deleting...</span>
              </Tag>
            ) : (
              <Tag
                onClick={() => {
                  auto_delete_bid(b);
                }}
                className={"bg-red-400"}
              >
                Delete Bid
              </Tag>
            )}
          </div>
        </Card>
      </PopUp>
    </>
  );
};

export const BidsListPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  // const { vault } = aucon;

  const params = useParams();
  // console.log("params", params);
  const vault = useMemo(() => {
    let v = getv(params, "vault");
    if (nils(v)) return null;
    return v.toLowerCase();
  }, [jstr(params)]);

  const is_owner = vault == aucon.vault;

  const [qovau] = useQueries([q_vaultinfo({ vault })]);
  const vdoc = useMemo(
    () => getv(qovau, "data.result", {}),
    [qovau.dataUpdatedAt],
  );

  const [qobidslist] = useQueries([q_vaubidslist({ vault: vault })]);
  const [bids, vmap, assetsinfo] = useMemo(() => {
    let bids = getv(qobidslist, "data.result.bids", []);
    let vmap = getv(qobidslist, "data.result.vmap", {});
    let assetsinfo = getv(qobidslist, "data.result.assetsinfo", {});
    return [bids, vmap, assetsinfo];
  }, [qobidslist.dataUpdatedAt]);

  const [mode, set_mode] = useState(psearch.mode ?? "sent");

  const filt = useMemo(() => {
    if (nils(vault)) return [];
    if (mode == "sent") return _.filter(bids, (b) => b.tok_reciever == vault);
    if (mode == "received") return _.filter(bids, (b) => b.tok_sender == vault);
  }, [mode, jstr(bids), vault]);

  useEffect(() => {
    let rem = { mode };
    upd_psearch(rem);
  }, [mode]);

  const bcon = {
    vault,
    assetsinfo,
    vmap,
    is_owner,
  };

  return (
    <>
      <BidsContext.Provider value={bcon}>
        <Helmet>
          <title>Bids List</title>
        </Helmet>

        <div className="h-page">
          <div className="max-w-[98vw] w-[80rem] mx-auto">
            <div className="h-[5rem]"></div>

            {qissuccesss(qovau) && (
              <>
                <Card className={"w-full"}>
                  <p className="resp-text--1 text-acc0">{vdoc?.vault_name}</p>
                  <p className="resp-text--3">{vault}</p>
                </Card>
              </>
            )}

            <div className="fr-cc resp-gap-2 my-2">
              {[
                ["sent", "Sent Bids"],
                ["received", "Received Bids"],
              ].map(([_mode, txt]) => {
                return (
                  <Tag
                    onClick={() => set_mode(_mode)}
                    className={twMerge(
                      "font-digi resp-text--1 -skew-x-12",
                      _mode == mode ? "bg-acc0/40" : "text-acc0",
                    )}
                  >
                    {txt}
                  </Tag>
                );
              })}
            </div>

            {qobidslist.isLoading ? (
              <Loader01c />
            ) : qiserr(qobidslist) ? (
              <p className="text-center text-red-300 resp-text--2">
                {qiserr(qobidslist)}
              </p>
            ) : qissuccesss(qobidslist) &&
              (_.isEmpty(bids) || _.isEmpty(filt)) ? (
              <p className="text-center text-yellow-300 resp-text--2">
                No Active {_.capitalize(mode)} Bids currently
              </p>
            ) : qissuccesss(qobidslist) && !_.isEmpty(bids) ? (
              <>
                <Card className="bg-reg  w-full ">
                  <table className={tablecn.table}>
                    <thead>
                      <tr
                        className={
                          "thintdrow text-acc0 text-center font-digi resp-text--2"
                        }
                      >
                        <td>MarketID</td>
                        {mode == "sent" ? (
                          <>
                            <td>Sent To</td>
                          </>
                        ) : mode == "received" ? (
                          <>
                            <td>Bidder</td>
                          </>
                        ) : (
                          <></>
                        )}
                        <td>Asset</td>
                        <td>Info</td>
                        <td>Price</td>
                        <td>Qty</td>
                        <td>ExpiresIn</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {filt.map((b) => {
                        let k = `${b.asset_type}:${b.token_id}`;
                        let asty = asset_types_map[b.asset_type];
                        let asinf = assetsinfo[k];
                        return (
                          <tr className="thintdrow resp-text--2">
                            <td>
                              <ToolTip
                                message={b.marketid}
                                msg_cn="w-max resp-text--4"
                              >
                                <span className="text-purple-400 font-mon resp-text--2">
                                  {b.marketid.slice(-5)}
                                </span>
                              </ToolTip>
                            </td>

                            {mode == "sent" ? (
                              <>
                                <td>
                                  <div className="min-w-[15rem] fr-sc">
                                    <ToolTip
                                      message={b.tok_sender}
                                      msg_cn="w-max resp-text--4"
                                    >
                                      <Tag onClick={copy_clip(b.tok_sender)}>
                                        <span className="text-acc0">
                                          {vmap[b.tok_sender]}
                                        </span>
                                      </Tag>
                                    </ToolTip>
                                  </div>
                                </td>
                              </>
                            ) : mode == "received" ? (
                              <>
                                <td>
                                  <div className="min-w-[15rem] fr-sc">
                                    <ToolTip
                                      message={b.tok_reciever}
                                      msg_cn="w-max resp-text--4"
                                    >
                                      <Tag onClick={copy_clip(b.tok_reciever)}>
                                        <span className="text-acc0">
                                          {vmap[b.tok_reciever]}
                                        </span>
                                      </Tag>
                                    </ToolTip>
                                  </div>
                                </td>
                              </>
                            ) : (
                              <></>
                            )}

                            <td>
                              <span className="px-2 resp-text--1">
                                {asty?.name}#{b.token_id}
                              </span>
                            </td>
                            <td>
                              <div className="fc-ss">
                                <p className="px-2 resp-text--2 text-acc0">
                                  {asinf?.name}
                                </p>
                                {b.asset_type == "core" && (
                                  <>
                                    <div className="fr-sc resp-gap-1 resp-text--3">
                                      <span>F{asinf.fno}</span>
                                      <span>{_.capitalize(asinf.type)}</span>
                                      <MiniElementTag
                                        element={asinf.element}
                                        className="resp-text--2"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="fr-sc">
                                <Link
                                  target={"_blank"}
                                  to={`/asset/${b.asset_type}/${b.token_id}${
                                    asinf.erc == 1155
                                      ? `?a=${b.tok_sender}`
                                      : ""
                                  }`}
                                >
                                  <Tag className="resp-text--2 border border-acc0 text-acc0">
                                    {`${parseFloat(b.amt).toString()} ${b.token}`}
                                  </Tag>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <Tag className="text-acc0 resp-text--1">
                                {b.qty}
                              </Tag>
                            </td>
                            <td>
                              <div className="fc-ss">
                                <span className="resp-text--3">
                                  {from_time_mini(b.expires_at)}
                                </span>
                                <span className="resp-text--4">
                                  {iso_format(b.expires_at)}
                                </span>
                              </div>
                            </td>
                            {is_owner && (
                              <>
                                {mode == "sent" ? (
                                  <>
                                    <td>
                                      <DeleteBidBtn b={b} />
                                    </td>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </>
            ) : (
              <></>
            )}

            <div className="h-[5rem]"></div>
          </div>
        </div>
      </BidsContext.Provider>
    </>
  );
};
