const o = {
  enterRace: "function userEnterRace(string memory raceid, uint hid) external",
  getMarketInfo:
    "function getMarketInfo(bytes32 marketid) external view returns (Market memory)",
  deleteMarket: "function deleteMarket(bytes32 marketid) external",
  executeMarket:
    "function executeMarket(bytes32 marketid, uint execqty) external",
  expireMarket: "function expireMarket(bytes32 marketid) external",
  validateMarket: "function validateMarket(bytes32 marketid) external",
  invalidateMarket: "function invalidateMarket(bytes32 marketid) external",
  createMarket:
    "function createMarket(string memory mtype, uint token_id, string memory asset_type, uint qty, uint amt, uint expduration, address est1155_token_owner) external",
};

export default o;
