import abi_dnamarket from "./DNAMarket/abi.json";
import abimethods_dnamarket from "./DNAMarket/abimethods.js";

const erc_abis = {
  721: [
    "function transferFrom(address from, address to, uint256 tokenId) external",
    "function approve(address to, uint256 tokenId) public",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  1155: [
    "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  20: [
    "function balanceOf(address account) external view returns (uint256)",
    "function transfer(address to, uint256 amount) external returns (bool)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
  ],
};

const erc_methods_abi = {
  20: {
    balanceOf:
      "function balanceOf(address account) external view returns (uint256)",
    transfer:
      "function transfer(address to, uint256 amount) external returns (bool)",
    allowance:
      "function allowance(address owner, address spender) external view returns (uint256)",
    approve:
      "function approve(address spender, uint256 amount) external returns (bool)",
  },
  721: {
    transferFrom:
      "function transferFrom(address from, address to, uint256 tokenId) external",
    approve: "function approve(address to, uint256 tokenId) public",
    isApprovedForAll:
      "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    setApprovalForAll:
      "function setApprovalForAll(address operator, bool approved) public",
  },
  1155: {
    safeTransferFrom:
      "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    isApprovedForAll:
      "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    setApprovalForAll:
      "function setApprovalForAll(address operator, bool approved) public",
  },
};

export const abimap = {
  20: erc_abis[20],
  721: erc_abis[721],
  1155: erc_abis[1155],
  dez: erc_abis[20],
  DEZ: erc_abis[20],
  weth: erc_abis[20],
  WETH: erc_abis[20],
  bgc: erc_abis[20],
  BGC: erc_abis[20],

  dnamarket: abi_dnamarket,
  market_weth: abi_dnamarket,
  market_dez: abi_dnamarket,
};

export const abimethodmap = {
  20: erc_methods_abi[20],
  721: erc_methods_abi[721],
  1155: erc_methods_abi[1155],
  dez: erc_methods_abi[20],
  DEZ: erc_methods_abi[20],
  weth: erc_methods_abi[20],
  WETH: erc_methods_abi[20],
  BGC: erc_methods_abi[20],
  bgc: erc_methods_abi[20],
  dnamarket: abimethods_dnamarket,
  market_weth: abimethods_dnamarket,
  market_dez: abimethods_dnamarket,
};

export default abimap;
