import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppContext } from "../App.js";
import {
  token_locpath,
  tokenacc_locpath,
  useMetaMaskContext,
  tokenaumode_locpath,
  tokenauth_locpath,
} from "./MetaMaskWrapper.js";
import {
  q_auth_verify_token,
  q_vaultinfo,
  qiserr,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import Layout from "../components/Layout.js";
import { backdropimg } from "../utils/links.js";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const get_auth_header = () => {
  let token = localStorage.getItem(token_locpath);
  let vault = localStorage.getItem(tokenacc_locpath);
  let aumode = localStorage.getItem(tokenaumode_locpath);
  if (vault) vault = vault.toLowerCase();
  return {
    "x-token": nils(token) ? null : token,
    "x-vault": nils(vault) ? null : vault,
    "x-aumode": nils(aumode) ? null : aumode,
  };
};

export const NoAuthBackdrop = (props) => {
  return (
    <div
      style={{
        height: `92vh`,
        background: `url(${backdropimg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backdropFilter: "blur(10px)",
      }}
      className="w-[100vw] overflow-hidden"
    >
      {props.children}
    </div>
  );
};

function AuthWrapper(props) {
  const { path, history } = useAppContext();
  const mmcon = useMetaMaskContext();

  const bypass = useMemo(() => {
    if (path == "/" || path == "/login" || path.startsWith("/race/"))
      return true;
    return false;
  }, [path]);

  const [refreshc, set_refreshc] = useState(0);
  const [aumode, account, token] = useMemo(() => {
    let aumode = localStorage.getItem(tokenaumode_locpath);

    if (aumode == "wallet") {
      if (!window.ethereum) return [null, null, null];
      const fn = () => {
        let account = window.ethereum.selectedAddress;
        if (!nils(account)) account = account.toLowerCase();
        let token = mmcon.get_token();
        // console.log("refreshc", refreshc, account, token);
        return [aumode, account, token];
      };
      return fn();
    } else if (aumode == "thirdweb") {
      // if (!t3con.connected) return [null, null, null];
      let account = localStorage.getItem(tokenacc_locpath);
      let token = localStorage.getItem(token_locpath);
      if (!token || !account) return [null, null, null];
      return [aumode, account, token];
    } else return [null, null, null];
  }, [refreshc]);

  useEffect(() => {
    if (!account && bypass == true) {
      setTimeout(() => {
        set_refreshc(refreshc + 1);
      }, 1000);
    }
  }, [bypass]);

  const vault = useMemo(() => {
    if (nils(account)) return null;
    return account?.toLowerCase();
  }, [account]);

  useEffect(() => {
    if (!window.ethereum) return;
    if (!mmcon.mm_connected) return;

    window.ethereum.on("accountsChanged", () => {
      console.log("accountsChanged");
      set_refreshc(refreshc + 1);
    });
  }, [mmcon.mm_connected]);

  const qo_enabled = !nils(vault) && !nils(token);
  const [qo_auth] = useQueries([
    {
      queryKey: ["auth", jstr({ vault, token })],
      queryFn: () => q_auth_verify_token({ vault, token }),
      staleTime: 5 * 60 * 1e3,
      enabled: qo_enabled,
    },
  ]);
  const [auth, autherr] = useMemo(() => {
    // console.log("qo_auth", qo_enabled, qo_auth);
    if (!qo_enabled) return [false, "couldnt find auth details"];

    let err = qiserr(qo_auth);

    if (err) {
      // history("/login");
      localStorage.removeItem(token_locpath);
      localStorage.removeItem(tokenacc_locpath);
      localStorage.removeItem(tokenaumode_locpath);
      localStorage.removeItem(tokenauth_locpath);
      return [false, err];
    }

    let auth = getv(qo_auth, "data.result.auth");
    if (auth) {
      localStorage.setItem(tokenauth_locpath, "true");
      return [true, null];
    } else {
      localStorage.setItem(tokenauth_locpath, "false");
      return [false, `couldn't authenticate`];
    }
  }, [qo_auth.dataUpdatedAt]);

  useEffect(() => {
    if (qo_auth.isLoading) return;
    if (auth == false && bypass == false) {
      // history("/login");
    }
  }, [auth, autherr, qo_auth.dataUpdatedAt]);

  const auth_refresh = async () => {
    set_refreshc(refreshc + 1);
    await cdelay(500);
    await qo_auth.refetch();
  };

  const logout = async () => {
    localStorage.removeItem(token_locpath);
    localStorage.removeItem(tokenacc_locpath);
    localStorage.removeItem(tokenaumode_locpath);
    localStorage.removeItem(tokenauth_locpath);
    await auth_refresh();
  };

  const [qo_vaultinfo] = useQueries([
    q_vaultinfo({ vault }, { enabled: auth == true }),
  ]);
  const vaultdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const sel_correct_chain = async () => {
    try {
      const corr_chainId = 137;
      const corr_chainId_hex = "0x" + corr_chainId.toString(16);

      const mm = window.ethereum;
      if (nils(mm)) return;
      let chainIdHex = await mm.request({ method: "eth_chainId" });
      const chainId = parseInt(chainIdHex, 16);
      if (chainId !== corr_chainId) {
        await mm.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: corr_chainId_hex }],
        });
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (auth == true) {
      sel_correct_chain();
    }
  }, [auth]);

  const aucon = {
    auth,
    account,
    vault,
    token,
    loading: qo_auth.isLoading,
    auth_refresh,
    logout,

    qo_auth,

    qo_vaultinfo,
    vaultdoc,
    aumode,
  };

  return (
    <AuthContext.Provider value={aucon}>{props.children}</AuthContext.Provider>
  );
}

export default AuthWrapper;
