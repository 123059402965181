import _ from "lodash";
import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  createContext,
} from "react";
import { Helmet } from "react-helmet-async";
import { Loader01c } from "../components/anims";
import { qiserr, qissuccess } from "../queries/queries";
import { getv } from "../utils/utils";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { asset_types_map } from "../contracts/contract_funcs.js";
import { AssetMiniCard } from "./VaultPage";

const WatchListContext = createContext({});
const useWatchList = () => useContext(WatchListContext);

export const WatchListPage = () => {
  const accon = useAccountContext();
  const { vault, watchlist, qo_watchlist } = accon;

  const pagetitle = useMemo(() => {
    return "Wishlist";
  }, []);

  const wcon = {
    watchlist,
    qo_watchlist,
    vault,
  };
  return (
    <WatchListContext.Provider value={wcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          {qo_watchlist.isLoading ? (
            <Loader01c />
          ) : qiserr(qo_watchlist) ? (
            <p class="resp-text--1 text-center text-red-300">
              {qiserr(qo_watchlist)}
            </p>
          ) : qissuccess(qo_watchlist) && _.isEmpty(watchlist) ? (
            <p class="resp-text--1 text-center text-yellow-300">
              {" "}
              Your WishList is Empty
            </p>
          ) : qissuccess(qo_watchlist) && !_.isEmpty(watchlist) ? (
            <>
              <div className="grid xs:grid-cols-2 lg:grid-cols-3 resp-gap-2">
                {watchlist.map((a) => {
                  let { asset_type, token_id } = a;
                  let erc = getv(asset_types_map[a.asset_type], "erc", 0);
                  if (erc == 721)
                    return (
                      <AssetMiniCard
                        {...{
                          token_id,
                          asset_type,
                          erc,
                        }}
                      />
                    );
                  else if (erc == 1155)
                    return (
                      <AssetMiniCard
                        {...{
                          token_id,
                          asset_type,
                          erc,
                          vault: a.owner,
                        }}
                      />
                    );
                  else return <></>;
                })}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </WatchListContext.Provider>
  );
};
