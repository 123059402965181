import _ from "lodash";
import { fpost } from "./fetch.js";
import { getv, jparse, jstr, nils } from "../utils/utils.js";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { get_auth_header } from "../wrappers/AuthWrapper.js";
import {
  get_dez_balance,
  get_t3_dez_balance,
  get_t3_weth_balance,
  get_weth_balance,
} from "../contracts/contract_funcs.js";
import { tokenaumode_locpath } from "../wrappers/MetaMaskWrapper.js";

const usedevloc = true;

export const qiserr = (q) => {
  if (getv(q, "data.err")) return getv(q, "data.err");
  if (q.status == "error") return "server error";
  if (q.status == "loading") return false;
  return false;
};

export const iserr = (d) => {
  if (getv(d, "err") || d.status == "error") return getv(d, "data.err");
  return false;
};

export const qissuccess = (q) => {
  return getv(q, "status") == "success" && !qiserr(q);
};

export const qissuccesss = (q) => {
  return (
    getv(q, "status") == "success" &&
    getv(q, "data.status") == "success" &&
    !qiserr(q)
  );
};

export const useStepQuery = ({
  key,
  par_ar,
  q_,
  params,
  lim = 1,
  options = {},
  ...props
}) => {
  // useEffect(() => console.log("useStepQuery", par_ar), [par_ar]);

  const [enabled_n, set_enabled_n] = useState(lim);
  const qs = useQueries(
    par_ar?.map((e, idx) => {
      let ext_options = {
        enabled: idx < enabled_n,
        ...options,
      };
      // return () => {return { test: 0 }};
      return q_(...e, ext_options);
    }),
  );
  const c = useMemo(() => {
    let ob = _.countBy(qs, (e) => e.status);
    return {
      success: ob.success ?? 0,
      loading: ob.loading ?? 0,
      error: ob.error ?? 0,
      idle: ob.idle ?? 0,
    };
  }, [qs]);

  useEffect(() => {
    set_enabled_n((parseInt(c.success / lim) + 1) * lim);
  }, [c.success]);

  const datas = useMemo(() => {
    // if (qs_count.loading !== 0) return [];
    let ob = _.chain(qs).filter({ status: "success" }).map("data").value();
    // console.log("all_bikes_data", ob);
    return ob;
  }, [jstr(_.map(qs, "dataUpdatedAt")), c]);

  return {
    qs,
    datas,
    c,
    n: par_ar.length,
    done: c.success,
    tot: par_ar.length,
    loading: c.idle + c.loading > 0,
  };
};

export const btbk =
  usedevloc && process.env.NODE_ENV == "development"
    ? `http://localhost:3005`
    : `https://api.dnaracing.run`;

export const btbk_races = `${btbk}/fbike/races`;
export const btbk_ledger = `${btbk}/fbike/ledger`;

export const polytxnbase = btbk_ledger.includes("dev")
  ? `https://mumbai.polygonscan.com/tx`
  : `https://polygonscan.com/tx`;
export const polytxnlink = (hash) => `${polytxnbase}/${hash}`;
export const polytxnidlink = (id) =>
  `${polytxnbase}/${id ? id.split(":")[0] : null}`;

export const q_auth_get_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_token = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_token`;
  return fpost(api, doc, {});
};

export const q_construct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      return fpost(api, doc);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_auconstruct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      let headers = get_auth_header();
      return fpost(api, doc, headers);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_vaultinfo = (doc, ext = {}) => {
  let o = q_construct("q_vaultinfo", `${btbk}/fbike/vault/info`, doc, ext);
  return o;
};

export const q_token_prices = (doc, ext = {}) => {
  let next = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_token_prices",
    `${btbk}/fbike/token_prices`,
    doc,
    next,
  );
  return o;
};

export const q_mm_weth_balance = (doc, ext) => {
  return {
    queryKey: ["q_mm_weth_balance", jstr(doc)],
    queryFn: async () => {
      try {
        let redid = `bal:${jstr(doc)}_weth`;
        let aumode = localStorage.getItem(tokenaumode_locpath);
        let bal =
          aumode == "thirdweb"
            ? await get_t3_weth_balance(doc?.wallet)
            : await get_weth_balance(doc?.wallet);
        if (!nils(bal)) localStorage.setItem(redid, jstr({ bal }));
        else bal = getv(jparse(localStorage.getItem(redid)), "bal");
        return { status: "success", result: { bal } };
      } catch (err) {
        return { status: "error", err: err.message };
      }
    },
    staleTime: 60 * 1000,
    ...ext,
  };
};
export const q_mm_dez_balance = (doc, ext) => {
  return {
    queryKey: ["q_mm_dez_balance", jstr(doc)],
    queryFn: async () => {
      try {
        let redid = `bal:${jstr(doc)}_dez`;
        let aumode = localStorage.getItem(tokenaumode_locpath);
        let bal =
          aumode == "thirdweb"
            ? await get_t3_dez_balance(doc?.wallet)
            : await get_dez_balance(doc?.wallet);
        if (!nils(bal)) localStorage.setItem(redid, jstr({ bal }));
        else bal = getv(jparse(localStorage.getItem(redid)), "bal");
        return { status: "success", result: { bal } };
      } catch (err) {
        return { status: "error", err: err.message };
      }
    },
    staleTime: 60 * 1000,
    ...ext,
  };
};

export const q_astokinfo = (doc, ext = {}) => {
  return q_construct("q_astokinfo", `${btbk}/fbike/dnamarket/info/i`, doc, ext);
};
export const q_astokinfo_report_falselist = (doc, ext = {}) => {
  return q_construct(
    "q_astokinfo_report_falselist",
    `${btbk}/fbike/dnamarket/info/report_falselist`,
    doc,
    ext,
  );
};
export const q_astokinfo_owner = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_owner",
    `${btbk}/fbike/dnamarket/info/owner`,
    doc,
    next,
  );
};
export const q_astokinfo_owner_cacref = (doc, ext = {}) => {
  return q_construct(
    "q_astokinfo_owner",
    `${btbk}/fbike/dnamarket/info/owner_clear_cache`,
    doc,
    ext,
  );
};
export const q_astokinfo_bidslist = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_bidslist",
    `${btbk}/fbike/dnamarket/info/bidslist`,
    doc,
    next,
  );
};
export const q_astokinfo_list = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_list",
    `${btbk}/fbike/dnamarket/info/list_new`,
    doc,
    next,
  );
};
export const q_astokinfo_txns = (doc, ext = {}) => {
  let next = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astokinfo_txns",
    `${btbk}/fbike/dnamarket/info/txns`,
    doc,
    next,
  );
};
export const q_astoktxns_relayback = (doc, ext = {}) => {
  return q_construct(
    "q_astoktxns_relayback",
    `${btbk}/fbike/dnamarket/info/txn_relayback`,
    doc,
    ext,
  );
};

export const q_vaultassets = (doc, ext = {}) => {
  return q_construct(
    `q_vaultassets`,
    `${btbk}/fbike/dnamarket/vault/assets`,
    doc,
    ext,
  );
};
export const q_astoksearch = (doc, ext = {}) => {
  return q_construct(
    `q_astoksearch`,
    `${btbk}/fbike/dnamarket/search`,
    doc,
    ext,
  );
};

export const q_astoklistings = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 40 * 1e3,
    refetchInterval: 40 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_astoklistings",
    `${btbk}/fbike/dnamarket/listings/new`,
    doc,
    ext,
  );
};

export const q_vaubidslist = (doc, ext = {}) => {
  return q_construct(
    "q_vaubidslist",
    `${btbk}/fbike/dnamarket/vault/bidslist`,
    doc,
    ext,
  );
};

export const q_activity = (doc, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_activity",
    `${btbk}/fbike/dnamarket/activity/get`,
    doc,
    ext,
  );
};

export const q_activity_recent = (doc, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_acttivity_recent",
    `${btbk}/fbike/dnamarket/activity/recent`,
    doc,
    ext,
  );
};

export const q_watchlist_get = (doc = {}, ext = {}) => {
  return q_construct(
    "q_watchlist_get",
    `${btbk}/fbike/dnamarket/vault/watchlist`,
    doc,
    ext,
  );
};

export const q_watchlist_add = (doc = {}, ext = {}) => {
  return q_construct(
    "q_watchlist_add",
    `${btbk}/fbike/dnamarket/vault/add_watchlist`,
    doc,
    ext,
  );
};

export const q_watchlist_del = (doc = {}, ext = {}) => {
  return q_construct(
    "q_watchlist_del",
    `${btbk}/fbike/dnamarket/vault/del_watchlist`,
    doc,
    ext,
  );
};
