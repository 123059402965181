import _ from "lodash";
import {
  cb_cn,
  class_cn,
  class_text,
  elementmap,
  gendermap,
} from "../utils/cn_map.js";
import { Tag } from "./utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { nils } from "../utils/utils.js";
import { useEffect, useRef, useState } from "react";
import skin_thumbnail from "../data/skin_thumbnail.jpg";
import skin_thumbnail_grayscale from "../data/skin_thumbnail_grayscale.jpg";

export const ElementTag = ({ element, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12 resp-text--4",
        elementmap[element]?.bg,
        className
      )}
    >
      <FontAwesomeIcon icon={elementmap[element]?.icon} />
      <span className="font-digi">{_.upperCase(element)}</span>
    </Tag>
  );
};

export const MiniElementTag = ({ element }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1",
        elementmap[element]?.text,
        "resp-text-1"
      )}
    >
      <FontAwesomeIcon icon={elementmap[element]?.icon} />
    </Tag>
  );
};

export const GenderTag = ({ gender }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        gendermap[gender]?.bg,
        "resp-text--2"
      )}
    >
      <FontAwesomeIcon icon={gendermap[gender]?.icon} />
      <span className="font-digi">{_.upperCase(gender)}</span>
    </Tag>
  );
};

export const MiniGenderTag = ({ gender }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1",
        gendermap[gender]?.text,
        "resp-text-1"
      )}
    >
      <FontAwesomeIcon icon={gendermap[gender]?.icon} />
    </Tag>
  );
};

export const TypeTag = ({ type, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        "bg-acc0/40",
        "resp-text--4",
        className
      )}
    >
      <span className="font-digi">{_.upperCase(type)}</span>
    </Tag>
  );
};

export const FNoTag = ({ fno, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        "bg-transparent",
        className
      )}
    >
      <span className="font-digi text-acc0">
        F{_.upperCase(fno)}
      </span>
    </Tag>
  );
};

export const ClassTag = ({ c, className, format = "t" }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        class_cn(c),
        className
      )}
    >
      <span className="font-digi">{class_text(c, format)}</span>
    </Tag>
  );
};

export const CBTag = ({ cb, className }) => {
  return (
    <Tag
      className={twMerge(
        "fr-sc resp-gap-1 transform -skew-x-12",
        cb_cn(cb),
        className
      )}
    >
      <span className="font-digi">{`CB${cb}00M`}</span>
    </Tag>
  );
};

export const gets_skinurl = (skin) => {
  if (nils(skin)) return null;
  const baseURL =
    "https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-skins/";
  const formattedSkin = skin.replace(/ /g, "+");
  const newImageURL = `${baseURL}${formattedSkin}/${formattedSkin}.jpg`;
  return newImageURL;
};

export const SkinCard = ({ className, skin, rarity }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  const url = gets_skinurl(skin);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            setLoaded(true);
          };
          observer.disconnect();
        }
      });
    });
    observer.observe(imgRef.current);
    return () => observer.disconnect();
  }, [url]);

  return (
    <div className={"relative w-full aspect-[2200/1600]"}>
      <div
        style={{
          background: `url(${skin_thumbnail})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className={twMerge(
          "img-obey-cont h-auto",
          "aspect-[2200/1600] w-full trasition duration-300"
        )}
      >
        {
          <div
            className={twMerge(
              "absolute w-full h-full fc-cc transition duration-500",
              "opacity-100"
            )}
          >
            <div className="w-full h-[40%]"></div>
            <div className="w-full h-[60%] bg-gradient-to-b from-transparent to-black">
              <div className="fc-ss gap-2 w-full h-full text-white p-1 ">
                <div className="flex-1"></div>
                <div className={twMerge("font-digi italic", "text-[0.8rem]")}>
                  {skin}
                </div>
                <div className="font-digi text-[0.7rem]">
                  {_.upperCase(rarity)}
                </div>
              </div>
            </div>
          </div>
        }
        <img
          ref={imgRef}
          src={loaded ? url : ""}
          alt={skin} // Recommended to provide an alt attribute for accessibility
          loading="lazy"
          thumbnailSrc={skin_thumbnail}
          style={loaded ? {} : { visibility: "hidden" }}
        />
      </div>
    </div>
  );
};

