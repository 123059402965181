import React, { useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import qs from "query-string";
import { createContext } from "react";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import HomePage from "./views/HomePage.js";
import Layout from "./components/Layout.js";
import AuthWrapper from "./wrappers/AuthWrapper.js";
import Login from "./views/Login.js";
import NoColor from "./wrappers/NoColor.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import { useQueries } from "react-query";
import { q_token_prices } from "./queries/queries.js";
import { getv } from "./utils/utils.js";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AssetPage } from "./views/AssetPage.js";
import { VaultPage } from "./views/VaultPage.js";
import { MarketListingsPage } from "./views/MarketListings.js";
import { BidsListPage } from "./views/BidsListPage.js";
import { ErrorBoundary } from "./utils/errbou.js";
import { ActivityPage } from "./views/ActivityPage.js";
import { WatchListPage } from "./views/WatchListPage.js";
import { ThirdWebLoginWrapper } from "./views/ThirdWebLogin.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);

export const tokdecn = (token) =>
  token == "WETH"
    ? 6
    : token == "ETH"
      ? 6
      : token == "DEZ"
        ? 0
        : (token = "USD" ? 2 : 0);
export const tokdecn2 = (token) =>
  token == "WETH"
    ? 3
    : token == "ETH"
      ? 3
      : token == "DEZ"
        ? 0
        : (token = "USD" ? 2 : 0);

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tokmap = useMemo(
    () => getv(qo_tokpri, "data.result"),
    [qo_tokpri.dataUpdatedAt],
  );

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,

    tokmap,
  };

  const maintenance = false;

  return (
    <div className="App bg-dark text-white">
      <NowContext.Provider value={{ now }}>
        <AppContext.Provider value={appcon}>
          <MetaMaskWrapper>
            <ThirdWebLoginWrapper>
              <AuthWrapper>
                <AccountWrapper>
                  <>
                    <Layout>
                      <HelmetProvider>
                        <Helmet>
                          <title>{"DNA Market"}</title>
                        </Helmet>

                        {maintenance ? (
                          <div className="fc-cc text-red-300 my-[30vh] resp-gap-2">
                            <FontAwesomeIcon
                              className="text-[3rem]"
                              icon={faTriangleExclamation}
                            />
                            <p className="resp-text-2">App Under Maintenance</p>
                            <p className="resp-text-0">We will be back soon</p>
                          </div>
                        ) : (
                          <Routes>
                            <Route path="/" exact element={<HomePage />} />
                            <Route
                              path="/asset/:asset_type/:token_id"
                              exact
                              element={<AssetPage />}
                            />
                            <Route
                              path="/vault/bids/:vault"
                              exact
                              element={<BidsListPage />}
                            />
                            <Route
                              path="/vault/:vault"
                              exact
                              element={<VaultPage />}
                            />
                            <Route
                              path="/wishlist"
                              exact
                              element={<WatchListPage />}
                            />
                            <Route
                              path="/market-listings"
                              exact
                              element={<MarketListingsPage />}
                            />
                            <Route
                              path="/activity"
                              exact
                              element={<ActivityPage />}
                            />
                          </Routes>
                        )}
                      </HelmetProvider>
                    </Layout>
                  </>
                </AccountWrapper>
              </AuthWrapper>
            </ThirdWebLoginWrapper>
          </MetaMaskWrapper>
          <NoColor />
        </AppContext.Provider>
      </NowContext.Provider>
    </div>
  );
}

export default App;
