import _ from "lodash";
import moment from "moment";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Card, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import {
  base64_to_json,
  dec,
  getv,
  json_to_base64,
  jstr,
  nils,
} from "../utils/utils.js";
import {
  SortHead,
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
  sort_fn,
  sort_list,
} from "../utils/filt.js";
import { InpAutoWrap, InpDropdown, set_state_ob } from "../components/input.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { elementmap, gendermap, tablecn } from "../utils/cn_map.js";
import { q_astoklistings, qiserr, qissuccesss } from "../queries/queries.js";
import { useQueries } from "react-query";
import { Loader01c } from "../components/anims.js";
import { Link } from "react-router-dom";
import { ElementTag, GenderTag } from "../components/ShortComps.js";
import { useAppContext, tokdecn2 } from "../App.js";
import { InpOnlyWrap } from "../components/input.js";
import { faUsd } from "@fortawesome/free-solid-svg-icons";

const MarkContext = createContext();
const useMarkContext = () => useContext(MarkContext);

const BaseTableHeads = () => {
  return (
    <>
      <td>
        <span className="resp-text--1 text-center">Name</span>
      </td>
    </>
  );
};
const BaseTableRow = ({ h }) => {
  return (
    <>
      <td>
        <span className="resp-text--1 text-acc0">{h?.name}</span>
      </td>
    </>
  );
};

const CoreFiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    element: {
      type: "options",
      path: "element",
      cfilter: true,
      label: "Element",
      show_label: true,
      options: _.keys(elementmap),
      filter_exception: [],
      vals: _.compact(basefilt?.element),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark ${elementmap[o]?.text}`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white ${elementmap[op]?.bg} -skew-x-12`;
      },
    },
    gender: {
      type: "options",
      path: "gender",
      cfilter: true,
      label: "Gender",
      show_label: true,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark ${gendermap[o]?.text}`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white ${gendermap[op]?.bg} -skew-x-12`;
      },
    },
    type: {
      type: "options",
      path: "type",
      cfilter: true,
      label: "Type",
      show_label: true,
      options: ["genesis", "morphed", "freak", "xclass"],
      filter_exception: [],
      vals: _.compact(basefilt?.type),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
    fno: {
      type: "range",
      path: "fno",
      cfilter: true,
      label: "#F.No",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.fno ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    sort_by: {
      type: "options-only",
      path: "sort_by",
      cfilter: true,
      label: "Sort By",
      show_label: true,
      base_cn: `${mainfiltbtn} `,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
      options: [
        "-price",
        "+price",
        "-races_n",
        "+races_n",
        "-win_p",
        "+win_p",
        "-profit",
        "+profit",
        "-speed",
        "+speed",
        "-speed_avg",
        "+speed_avg",
      ],
      vals: basefilt?.sort_by,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
    races_n: {
      type: "range",
      path: "races_n",
      cfilter: false,
      label: "#Races",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.races_n ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    win_p: {
      type: "range",
      path: "win_p",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.win_p ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    profit: {
      type: "range",
      path: "profit",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.profit ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    speed: {
      type: "range",
      path: "speed",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.speed ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    speed_avg: {
      type: "range",
      path: "speed_avg",
      cfilter: false,
      label: "",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.speed_avg ?? {
        cb: undefined,
        mi: undefined,
        mx: undefined,
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "core") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-core", filters: filt, set_filters: set_filt };
  const cbinpargs = (k) => {
    const text_fn = (o) => (nils(o) ? "--" : o == "all" ? "All" : `CB${o}`);
    const val = getv(filt, `${k}.vals.cb`);
    return {
      options: ["all", 10, 12, 14, 16, 18, 20, 22, 24],
      option_cn: "p-1",
      txt: text_fn(val),
      text_fn,
      setter: (v) => {
        set_state_ob(filt, set_filt, `${k}.vals.cb`, v);
      },
    };
  };

  return (
    <>
      <div className="w-full fr-sc wrap max-w-full resp-text--2">
        <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "fno" }} />
      </div>
      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem]">#Races</span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("races_n")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "races_n" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6rem]">Win%</span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("win_p")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "win_p" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6.1arem]">
          Profit[usd]
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("profit")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "profit" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6.1arem]">
          Max.Speed{" "}
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("speed")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "speed" }} />
        </div>
      </div>

      <div className="fr-sc resp-gap-2">
        <span className="text-acc0 font-digi italic w-[6.1arem]">
          Avg.Speed{" "}
        </span>
        <div class="w-max">
          <InpDropdown {...cbinpargs("speed_avg")} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "speed_avg" }} />
        </div>
      </div>

      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>
      <div class="fr-sc">
        <InpAutoWrap {...{ ...inpargs, idd: "sort_by" }} />
      </div>

      <div className="fr-sc">
        <Tag
          onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};
const CoreTableHeads = () => {
  const con = useMarkContext();
  const { sorts, set_sorts, sort_ob, qolists } = con;
  const sortargs = { sorts, set_sorts, sort_ob };

  const headmap = useMemo(
    () => getv(qolists, "data.result.headmap") ?? {},
    [qolists.dataUpdatedAt],
  );
  return (
    <>
      <td>
        {/* <span className="resp-text--1">Name</span> */}
        <SortHead {...{ ...sortargs, k: "name" }} />
      </td>
      <td>
        {/* <span className="resp-text--1">Type</span> */}
        <SortHead {...{ ...sortargs, k: "type" }} />
      </td>
      <td>
        {/* <span className="resp-text--1">Element</span> */}
        <SortHead {...{ ...sortargs, k: "element" }} />
      </td>
      <td>
        {/* <span className="resp-text--1">Gender</span>  */}
        <SortHead {...{ ...sortargs, k: "gender" }} />
      </td>
      <td>
        {/* <span className="resp-text--1">F.No</span> */}
        <SortHead {...{ ...sortargs, k: "fno" }} />
      </td>
      <td>
        <div class="fc-cc">
          <SortHead {...{ ...sortargs, k: "races_n" }} />
          <span className="text-yellow-300 resp-text--3">
            {headmap?.races_n}
          </span>
        </div>
      </td>
      <td>
        <div class="fc-cc">
          <SortHead {...{ ...sortargs, k: "win_p" }} />
          <span className="text-yellow-300 resp-text--3">{headmap?.win_p}</span>
        </div>
      </td>
      <td>
        <div class="fc-cc">
          <SortHead {...{ ...sortargs, k: "profit" }} />
          <span className="text-yellow-300 resp-text--3">
            {headmap?.profit}
          </span>
        </div>
      </td>
      <td>
        <div class="fc-cc">
          <SortHead {...{ ...sortargs, k: "speed" }} />
          <span className="text-yellow-300 resp-text--3">{headmap?.speed}</span>
        </div>
      </td>

      <td>
        <div class="fc-cc">
          <SortHead {...{ ...sortargs, k: "speed_avg" }} />
          <span className="text-yellow-300 resp-text--3">
            {headmap?.speed_avg}
          </span>
        </div>
      </td>
    </>
  );
};
const CoreTableRow = ({ h }) => {
  return (
    <>
      <td>
        <Link
          to={`https://fbike.dnaracing.run/bike/${h.token_id}?subtab=summary&tab=races`}
          className=""
          target="_blank"
        >
          <p className="resp-text--1 text-acc0 font-digi xs:min-w-[5rem] lg:min-w-[10rem] ">
            {h.name}
          </p>
        </Link>
      </td>
      <td>
        <span className="resp-text--1">{_.capitalize(h.type)}</span>
      </td>
      <td>
        <ElementTag className="resp-text--2" element={h.element} />
      </td>
      <td>
        <GenderTag className="resp-text--2" gender={h.gender} />
      </td>
      <td>
        <span className="resp-text--1 text-acc0 font-digi italic">{`F${h.fno}`}</span>
      </td>
      <td>
        <span className="resp-text--1 text-acc0 font-digi">{`${h.races_n}`}</span>
      </td>
      <td>
        <span className="resp-text--1 text-acc0 font-digi">{`${dec(h.win_p * 100, 2)}%`}</span>
      </td>
      <td>
        <div
          className={twMerge(
            "fr-sc resp-gap-1",
            h.profit > 0 ? "text-green-400" : "text-red-400",
          )}
        >
          <FontAwesomeIcon icon={faUsd} />
          <span className="resp-text--2 w-[4rem] font-digi">{`${dec(h.profit, 0)}`}</span>
        </div>
      </td>
      <td className={twMerge("text-green-200")}>
        <span className="resp-text--2 font-digi">{`${dec(h.speed, 2)}`}</span>
      </td>

      <td className={twMerge("text-green-200")}>
        <span className="resp-text--2 font-digi">{`${dec(h.speed_avg, 2)}`}</span>
      </td>
    </>
  );
};

const SkinTableHeads = () => {
  return (
    <>
      <td>
        <span className="resp-text--1">Name</span>
      </td>
      <td>
        <span className="resp-text--1">Rarity</span>
      </td>
    </>
  );
};
const SkinTableRow = ({ h }) => {
  return (
    <>
      <td>
        <p className="resp-text--1 text-acc0 font-digi min-w-[10rem] ">
          {h.name}
        </p>
      </td>
      <td>
        <span className="resp-text--1">{_.capitalize(h.rarity)}</span>
      </td>
    </>
  );
};
const SkinFiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    rarity: {
      type: "options",
      path: "rarity",
      cfilter: true,
      label: "rarity",
      show_label: true,
      options: [
        "common",
        "uncommon",
        "rare",
        "epic",
        "legendary",
        "ultimate",
        "exclusive",
      ],
      filter_exception: [],
      vals: _.compact(basefilt?.rarity),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white bg-green-400/30 -skew-x-12`;
      },
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-core", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="w-full fr-sc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "rarity" }} />
      </div>

      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const GODTableHeads = BaseTableHeads;
const GODTableRow = ({ h }) => {
  return (
    <>
      <td>
        <p className="resp-text--1 text-acc0 font-digi min-w-[10rem] ">
          {h.name}
        </p>
      </td>
    </>
  );
};

const GODFiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const SkinLootBoxV1TableHeads = BaseTableHeads;
const SkinLootBoxV1TableRow = BaseTableRow;
const SkinLootBoxV1FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const SkinLootBoxV2TableHeads = BaseTableHeads;
const SkinLootBoxV2TableRow = BaseTableRow;
const SkinLootBoxV2FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const SkinLootBoxV3TableHeads = BaseTableHeads;
const SkinLootBoxV3TableRow = BaseTableRow;
const SkinLootBoxV3FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const CoreLootBoxV2TableHeads = BaseTableRow;
const CoreLootBoxV2TableRow = BaseTableRow;
const CoreLootBoxV2FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const SkinLootBoxV101V2TableHeads = BaseTableHeads;
const SkinLootBoxV101V2TableRow = BaseTableRow;
const SkinLootBoxV101V2FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const SkinGameLootBoxTableHeads = BaseTableHeads;
const SkinGameLootBoxTableRow = BaseTableRow;
const SkinGameLootBoxFiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    token_id: {
      type: "options",
      path: "token_id",
      cfilter: true,
      label: "Rarity",
      show_label: true,
      options: [1, 2, 3, 4, 5, 6],
      filter_exception: [],
      vals: _.compact((basefilt?.token_id || []).map((e) => parseInt(e))),
      txt_fn: (o) => {
        return o == 1
          ? "Common"
          : o == 2
            ? "Uncommon"
            : o == 3
              ? "Rare"
              : o == 4
                ? "Legendary"
                : o == 5
                  ? "Ultimate"
                  : o == 6
                    ? "Exclusive"
                    : "--";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token_id" }} />
        </div>
      </div>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const PrizeBoxTableHeads = BaseTableHeads;
const PrizeBoxTableRow = BaseTableRow;
const PrizeBoxFiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const DNA_Assets_TableHeads = BaseTableHeads;
const DNA_Assets_TableRow = BaseTableRow;
const DNA_Assets_FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    element: {
      type: "options",
      path: "token_id",
      cfilter: true,
      label: "Asset",
      show_label: true,
      options: [1],
      filter_exception: [],
      vals: _.compact(basefilt?.token_id),
      txt_fn: (o) => {
        if (o == 1) return "Grand Final Ticket #2024";
        return o;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white bg-acc0/40 -skew-x-12`;
      },
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="w-full fr-sc wrap max-w-full resp-text--2">
        <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
      </div>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const FactionsAgents_TableHeads = () => {
  const con = useMarkContext();
  const { sorts, set_sorts, sort_ob, qolists } = con;
  const sortargs = { sorts, set_sorts, sort_ob };

  return (
    <>
      <td>
        {/* <span className="resp-text--1">Name</span> */}
        <SortHead {...{ ...sortargs, k: "name" }} />
      </td>
      <td>
        {/* <span className="resp-text--1">Faction</span> */}
        <SortHead {...{ ...sortargs, k: "faction" }} />
      </td>

      <td>
        {/* <span className="resp-text--1">Faction</span> */}
        <SortHead {...{ ...sortargs, k: "rarity" }} />
      </td>
    </>
  );
};
const FactionsAgents_TableRow = ({ h }) => {
  return (
    <>
      <td>
        <Link
          target="_blank"
          to={`/asset/factionsagents/${h.token_id}`}
          className=""
        >
          <p className="resp-text--1 text-acc0 font-digi xs:min-w-[5rem] lg:min-w-[10rem] ">
            {h.name}
          </p>
        </Link>
      </td>
      <td>
        <span className="resp-text--1">
          {nils(h?.faction)
            ? ""
            : _.chain(h.faction).split("-").map(_.capitalize).join(" ").value()}
        </span>
      </td>
      <td>
        <span className="resp-text--1">{_.capitalize(h.rarity)}</span>
      </td>
    </>
  );
};
const FactionsAgents_FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    faction: {
      type: "options",
      path: "token_id",
      cfilter: true,
      label: "Faction",
      show_label: true,
      options: ["ordinem", "project-paragon", "root-prime", "the-divine-wind"],
      filter_exception: [],
      vals: _.compact(basefilt?.faction),
      txt_fn: (o) => {
        if (nils(o)) return "--";
        return _.chain(o).split("-").map(_.upperFirst).join(" ").value();
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white bg-acc0/40 -skew-x-12`;
      },
    },
    rarity: {
      type: "options",
      path: "token_id",
      cfilter: true,
      label: "Rarity",
      show_label: true,
      options: ["standard", "legendary"],
      filter_exception: [],
      vals: _.compact(basefilt?.rarity),
      txt_fn: (o) => {
        if (nils(o)) return "--";
        return _.chain(o).split("-").map(_.upperFirst).join(" ").value();
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `shadow shadow-white transform text-white bg-acc0/40 -skew-x-12`;
      },
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "factionsagents") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="w-full fr-sc wrap max-w-full resp-text--2">
        <InpAutoWrap {...{ ...inpargs, idd: "faction" }} />
      </div>
      <div className="w-full fr-sc wrap max-w-full resp-text--2">
        <InpAutoWrap {...{ ...inpargs, idd: "rarity" }} />
      </div>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const FactiionsAgentsBox_TableHeads = BaseTableHeads;
const FactiionsAgentsBox_TableRow = BaseTableRow;
const FactiionsAgentsBox_FiltSection = ({ basefilt = {} }) => {
  const mcon = useMarkContext();
  const { asset_type, set_valfilt: set_main_valfilt } = mcon;
  // const basefilt = {};
  const [filt, set_filt] = useState({
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price",
      show_label: true,
      filter_exception: [],
      vals: basefilt?.price ?? { mi: undefined, mx: undefined },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    },
    token: {
      type: "options",
      path: "token",
      cfilter: true,
      label: "token",
      show_label: false,
      options: ["WETH", "ETH", "DEZ", "MATIC"],
      filter_exception: [],
      vals: _.compact(basefilt?.token),
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark text-white`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        return `text-white bg-acc0/40 transform -skew-12`;
      },
    },
  });
  const valfilt = useMemo(() => {
    const v = gen_valob_from_filters(filt);
    // console.log("valfilt", asset_type, v);
    return v;
  }, [asset_type, jstr(filt)]);

  useEffect(() => {
    if (asset_type == "skin") {
      set_main_valfilt(valfilt);
    }
  }, [valfilt, asset_type]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const inpargs = { fkey: "market-god", filters: filt, set_filters: set_filt };

  return (
    <>
      <div className="fr-sc">
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
        </div>
        <div className="w-max">
          <InpAutoWrap {...{ ...inpargs, idd: "token" }} />
        </div>
      </div>

      <div className="fr-sc">
        <Tag
          // onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
      </div>
    </>
  );
};

const asset_types = _.chain([
  [
    "core",
    721,
    "Core",
    CoreFiltSection,
    "fbike-dnaracing",
    "0xce8090de88bba13d3cea5d73f8baf1f1c1a61b16",
    CoreTableHeads,
    CoreTableRow,
  ],
  [
    "skin",
    721,
    "Skin",
    SkinFiltSection,
    "fbikeskin-dnaracing",
    "0xcd0783c0e2b0a68a64ba7c5e0f99097945397cf3",
    SkinTableHeads,
    SkinTableRow,
  ],
  [
    "god",
    721,
    "GOD",
    GODFiltSection,
    "godtoken-dnaracing",
    "0x28aabbd51a634d186f79ffbfa84fb70d119dbb05",
    GODTableHeads,
    GODTableRow,
  ],
  [
    "corelootboxv2",
    1155,
    "Core LootBox V2",
    CoreLootBoxV2FiltSection,
    "",
    "0x20dd967458922cDC21b16F69e043c7C918bcE8eF",
    CoreLootBoxV2TableHeads,
    CoreLootBoxV2TableRow,
  ],
  [
    "skinlootboxv1",
    1155,
    "Skin LootBox V1",
    SkinLootBoxV1FiltSection,
    "",
    "0xbcDEB62e65666A13b4dF9fab76258b3164Da18bd",
    SkinLootBoxV1TableHeads,
    SkinLootBoxV1TableRow,
  ],
  [
    "skinlootboxv2",
    1155,
    "Skin LootBox V2",
    SkinLootBoxV2FiltSection,
    "",
    "0xdb2fe592e2872eb7a63f308489b229b8b858273c",
    SkinLootBoxV2TableHeads,
    SkinLootBoxV2TableRow,
  ],
  [
    "skinlootboxv3",
    1155,
    "Skin LootBox V3",
    SkinLootBoxV3FiltSection,
    "",
    "0x038e70f5d0597fbe4b0f24c2acd93759da59c35f",
    SkinLootBoxV3TableHeads,
    SkinLootBoxV3TableRow,
  ],

  [
    "skinlootboxv101",
    1155,
    "Hallow Lootbox",
    SkinLootBoxV101V2FiltSection,
    "",
    "0x459698b869a671ecdF7c7714B101eb8B8891C2C5",
    SkinLootBoxV101V2TableHeads,
    SkinLootBoxV101V2TableRow,
  ],
  [
    "skingamelootbox",
    1155,
    "Rarity Box",
    SkinGameLootBoxFiltSection,
    "",
    "0x7D16c167f9d9241A538ade09FD64AC25cA50132E",
    SkinGameLootBoxTableHeads,
    SkinGameLootBoxTableRow,
  ],
  [
    "prizebox",
    1155,
    "Prize Box",
    PrizeBoxFiltSection,
    "",
    "0x59768e3a04b0598b128ba5039f24b7bd61cbb24a",
    PrizeBoxTableHeads,
    PrizeBoxTableRow,
  ],

  [
    "dnaassets",
    1155,
    "DNA Assets",
    DNA_Assets_FiltSection,
    "",
    "0x05eb96ca2742549d96d1da37ba830e0938c879b0",
    DNA_Assets_TableHeads,
    DNA_Assets_TableRow,
  ],
  [
    "factionsagents",
    721,
    "Factions Agents",
    FactionsAgents_FiltSection,
    "",
    "0xcafb20935c16db1d4917027b70cb690bdaec89f9",
    FactionsAgents_TableHeads,
    FactionsAgents_TableRow,
  ],
  [
    "factionsagentsbox",
    1155,
    "Factions Agents Box",
    FactiionsAgentsBox_FiltSection,
    "",
    "0xb704e08173f23f52e86a08b99e26ae9ec98c9a7d",
    FactiionsAgentsBox_TableHeads,
    FactiionsAgentsBox_TableRow,
  ],
])
  .map((e) => {
    let i = 0;
    // console.log(e)
    return {
      asset_type: e[i++],
      erc: e[i++],
      txt: e[i++],
      AsFilt: e[i++],
      oscoll: e[i++],
      address: e[i++],
      TableHeads: e[i++],
      TableRow: e[i++],
    };
  })
  .keyBy("asset_type")
  .value();

export const MarketListingsPage = () => {
  const { psearch, upd_psearch } = useAppContext();

  const [asset_type, set_asset_type] = useState(psearch.asset_type ?? "core");
  const [valfilt, set_valfilt] = useState(
    nils(psearch.f) ? base64_to_json(psearch.f) : {},
  );
  const [reqbody, set_reqbody] = useState({});
  const [c, set_c] = useState(0);

  const AsTy = asset_types[asset_type];

  useEffect(() => {
    const r = {
      asset_type,
      filt: valfilt,
      c,
    };
    // console.log(r);
    set_reqbody(r);
  }, [c, asset_type]);

  const [qolists] = useQueries([q_astoklistings(reqbody, { enabled: c > 0 })]);
  const [lists, vmap] = useMemo(() => {
    if (!qissuccesss(qolists)) return [[], {}];
    let hs = getv(qolists, "data.result.lists", []);
    let vmap = getv(qolists, "data.result.vmap", {});

    if (!_.isEmpty(hs)) {
      hs = _.map(hs, (h, i) => {
        h.id = `${h.asset_type}-${h.token_id}`;
        // h.id = i;
        return h;
      });
    }

    return [hs, vmap];
  }, [qolists.dataUpdatedAt]);

  useEffect(() => {
    // console.log("megavalfilt", valfilt);
  }, [jstr(valfilt)]);

  const asty0 = asset_types[asset_type];

  useEffect(() => {
    let rem = {};
    rem.asset_type = asset_type;
    rem.f = json_to_base64(valfilt);
    upd_psearch(rem);
  }, [jstr(valfilt), asset_type]);

  const [sorts, set_sorts] = useState([]);
  const sort_ob = {
    token_id: { disp: "TokenID", fn: (i1) => sort_fn(i1, "token_id", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "info.name", "txt") },
    element: {
      disp: "Element",
      fn: (i1) => sort_fn(i1, "info.element", "txt"),
    },
    type: { disp: "Type", fn: (i1) => sort_fn(i1, "info.type", "txt") },
    gender: { disp: "Gender", fn: (i1) => sort_fn(i1, "info.gender", "txt") },
    fno: { disp: "F.No", fn: (i1) => sort_fn(i1, "info.fno", "n") },
    races_n: { disp: "#Race", fn: (i1) => sort_fn(i1, "info.races_n", "n") },
    win_p: { disp: "Win%", fn: (i1) => sort_fn(i1, "info.win_p", "n") },
    profit: { disp: "Profit", fn: (i1) => sort_fn(i1, "info.profit", "n") },
    speed: { disp: "Max.Speed", fn: (i1) => sort_fn(i1, "info.speed", "n") },
    speed_avg: {
      disp: "Avg.Speed",
      fn: (i1) => sort_fn(i1, "info.speed_avg", "n"),
    },

    dna_price: { disp: "DNA Price", fn: (i1) => sort_fn(i1, "dna.0.amt", "n") },
    os_price: { disp: "OS Price", fn: (i1) => sort_fn(i1, "os.0.amt", "n") },
  };

  const sorted_lists = useMemo(() => {
    if (_.isEmpty(lists)) return [];

    let ar = sort_list({
      list: lists,
      sorts,
      sort_ob,
      id: "id",
    });

    return ar;
  }, [jstr(lists), jstr(sorts), jstr(sort_ob)]);

  const mcon = {
    valfilt,
    set_valfilt,
    asset_type,
    set_asset_type,
    AsTy,

    qolists,
    lists,
    sorted_lists,

    sorts,
    set_sorts,
    sort_ob,
  };

  return (
    <MarkContext.Provider value={mcon}>
      <div className="page-wrapper">
        <div className="h-page">
          <div className="max-w-[98vw] w-[80rem] mx-auto">
            <div className="h-[5rem]"></div>
            {/* <p className="text-center text-acc0 resp-text-1">Market</p> */}

            <div className="grid grid-cols-3 resp-gap-2 my-2 font-digi text-center">
              {_.keys(asset_types).map((_asset_type) => {
                const active = asset_type == _asset_type;
                const asty = asset_types[_asset_type];
                return (
                  <Tag
                    onClick={() => set_asset_type(_asset_type)}
                    className={twMerge(
                      "resp-text-1 transition duration-300",
                      active ? "bg-acc0/70 text-white -skew-x-12" : "text-acc0",
                    )}
                  >
                    {asty.txt}
                  </Tag>
                );
              })}
            </div>

            <Card className={"w-reg w-[98%] resp-px-4"}>
              {!nils(AsTy) && AsTy.AsFilt && <AsTy.AsFilt basefilt={valfilt} />}
              <div className="fr-sc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => {
                    setTimeout(() => {
                      set_c(c + 1);
                    }, 500);
                  }}
                  className="bg-acc0/40 -skew-x-12 text-white resp-text--1"
                >
                  {"Search"}
                </Tag>
              </div>
            </Card>

            <div className="h-[1rem]">
              {qolists.isLoading ? (
                <div className="fr-cc resp-gap-2 text-acc0">
                  <Loader01c size="s" />
                  <span>Loading...</span>
                </div>
              ) : qiserr(qolists) ? (
                <div className="fr-cc resp-gap-2 text-red-400">
                  <span>Error!! {qiserr(qolists)}</span>
                </div>
              ) : qissuccesss(qolists) && _.isEmpty(lists) ? (
                <div className="fr-cc resp-gap-2 text-red-400">
                  <span>No Active Listings [try different filters]</span>
                </div>
              ) : qissuccesss(qolists) && !_.isEmpty(lists) ? (
                <Card className={"w-full max-w-[98vw] overflow-auto"}>
                  <table className={tablecn.table}>
                    <thead>
                      <tr className="thintdrow text-acc0">
                        <td>
                          <span className="resp-text--1">Asset</span>
                        </td>
                        <td>
                          <span className="resp-text--1">
                            <SortHead
                              sorts={sorts}
                              set_sorts={set_sorts}
                              sort_ob={sort_ob}
                              k="token_id"
                            />
                          </span>
                        </td>
                        {AsTy?.TableHeads && <AsTy.TableHeads />}
                        <td>
                          {/*
                          <span>DNA Price</span>
                          */}
                          <SortHead
                            sorts={sorts}
                            set_sorts={set_sorts}
                            sort_ob={sort_ob}
                            k="dna_price"
                          />
                        </td>
                        {asty0.erc == 1155 ? (
                          <td>
                            <span className="resp-text--1">Seller</span>
                          </td>
                        ) : (
                          <td>
                            {/*
                              <span>OS Price</span>
                            */}

                            <SortHead
                              sorts={sorts}
                              set_sorts={set_sorts}
                              sort_ob={sort_ob}
                              k="os_price"
                            />
                          </td>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {sorted_lists.map((h, i) => {
                        const asty = asset_types[h.asset_type];
                        if (h.asset_type !== AsTy.asset_type) return <></>;

                        let dna = h.dna;
                        let os = h.os;
                        const dnalink = nils(dna)
                          ? null
                          : asty.erc == 721
                            ? `/asset/${asset_type}/${h.token_id}`
                            : `/asset/${asset_type}/${h.token_id}?a=${dna.seller}`;
                        const oslink =
                          os &&
                          `https://opensea.io/assets/matic/${asty.address}/${h.token_id}`;

                        // console.log(h.info, AsTy.TableRow, AsTy.TableHeads);

                        return (
                          <tr
                            className="thintdrow"
                            // key={`${h.asset_type}-${h.token_id}`}
                          >
                            <td>
                              <Tag>
                                <span className="resp-text--1">{asty.txt}</span>
                              </Tag>
                            </td>
                            <td>
                              <Tag>
                                <span className="resp-text--1">
                                  #{h.token_id}
                                </span>
                              </Tag>
                            </td>
                            {AsTy?.TableRow && h.info && (
                              <AsTy.TableRow h={h.info} />
                            )}
                            <td>
                              {nils(dna) || _.isEmpty(dna) ? (
                                <Tag className="border border-slate-600 text-slate-600">
                                  <p className="resp-text--1">--</p>
                                </Tag>
                              ) : (
                                dna.map((o) => {
                                  let dnalink =
                                    asty.erc == 721
                                      ? `/asset/${asset_type}/${h.token_id}`
                                      : `/asset/${asset_type}/${h.token_id}?a=${o.seller}`;
                                  return (
                                    <Link to={dnalink} target="_blank">
                                      <p className="resp-text--1 text-acc0 p-2 border border-acc0 bold rounded-md">
                                        {`${dec(
                                          o.amt,
                                          tokdecn2(o.token),
                                        )} ${o.token}`}

                                        {asty.erc == 1155
                                          ? ` avlb.=${o.qty}`
                                          : ""}
                                      </p>
                                    </Link>
                                  );
                                })
                              )}
                            </td>
                            {asty.erc == 1155 ? (
                              <td>
                                <Link to={dnalink} target="_blank">
                                  <div className="fc-ss text-acc0 px-2 bold rounded-md">
                                    <p className="resp-text--1">
                                      {vmap[dna.seller]}
                                    </p>
                                    <p className="resp-text--4">{dna.seller}</p>
                                  </div>
                                </Link>
                              </td>
                            ) : (
                              <td>
                                {nils(os) || _.isEmpty(os) ? (
                                  <Tag className="border border-slate-600 text-slate-600">
                                    <p className="resp-text--1">--</p>
                                  </Tag>
                                ) : (
                                  <>
                                    {os.map((o) => {
                                      let oslink = `https://opensea.io/assets/matic/${asty.address}/${h.token_id}`;
                                      return (
                                        <Link to={oslink} target="_blank">
                                          <p className="resp-text--1 text-purple-400 p-2 border border-purple-400 bold rounded-md">
                                            {`${dec(
                                              o.amt,
                                              tokdecn2(o.token),
                                            )} ${o.token}`}
                                          </p>
                                        </Link>
                                      );
                                    })}
                                  </>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              ) : (
                <></>
              )}
            </div>
            <div className="h-[5rem]"></div>
          </div>
        </div>
      </div>
    </MarkContext.Provider>
  );
};
