import { ethers } from "ethers";
import { iso, nano, nils, toeth, tofeth } from "../../utils/utils.js";
import abi from "./abi.json";
import { contractAddress_list } from "../constants";

const getgasinfo = () => ({
  // gasLimit: 220000,
});

const blocktime_iso = (n) => iso(Number(n) * 1000);

class Contract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async setAssetCategory(_asset_name, _assetaddress, _erc) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.setAssetCategory(
      _asset_name,
      _assetaddress,
      _erc,
      gasinfo,
    );
    const resp = await tx.wait();
    return resp;
  }

  async setPaymentToken(_skinCon, extinfo = {}) {
    const gasinfo = await getgasinfo();
    const info = { ...gasinfo, ...extinfo };
    const tx = await this.contract.setPaymentToken(_skinCon, info);
    const resp = await tx.wait();
    return resp;
  }

  parseMarket(a) {
    let i = 0;
    let o = {
      marketid: a[i++].toLowerCase(),
      mtype: a[i++],
      valid: a[i++],
      token_id: Number(a[i++]),
      asset_type: a[i++],
      qty: Number(a[i++]),
      amt_wei: a[i++],
      tok_sender: a[i++]?.toLowerCase(),
      tok_reciever: a[i++]?.toLowerCase(),
      created_at: a[i++],
      expires_at: a[i++],
    };
    o.amt = tofeth(o.amt_wei);
    o.amt_wei = o.amt_wei.toString();
    o.created_at = iso(Number(o.created_at) * 1000);
    o.expires_at = iso(Number(o.expires_at) * 1000);
    return o;
  }

  async getMarketInfo(marketid) {
    let a = await this.contract.getMarketInfo(marketid);
    return this.parseMarket(a);
  }

  async createMarket(
    mtype,
    token_id,
    asset_type,
    qty,
    amt,
    expduration,
    est1155_token_owner,
  ) {
    let gasinfo = await getgasinfo();
    if (nils(est1155_token_owner)) est1155_token_owner = ethers.ZeroAddress;
    let callargs = [
      mtype,
      token_id,
      asset_type,
      qty,
      amt,
      expduration,
      est1155_token_owner,
      gasinfo,
    ];
    // console.log("createmarket", callargs);
    let tx = await this.contract.createMarket(...callargs);
    let resp = tx.wait();
    return resp;
  }

  async validateMarket(marketid) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.validateMarket(marketid, gasinfo);
    const resp = await tx.wait();
    return resp;
  }

  async invalidateMarket(marketid) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.invalidateMarket(marketid, gasinfo);
    const resp = await tx.wait();
    return resp;
  }

  async deleteMarket(marketid) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.deleteMarket(marketid, gasinfo);
    const resp = await tx.wait();
    return resp;
  }

  async executeMarket(marketid, execqty) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.executeMarket(marketid, execqty, gasinfo);
    const resp = await tx.wait();
    return resp;
  }

  async expireMarket(marketid) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.expireMarket(marketid, gasinfo);
    const resp = await tx.wait();
    return resp;
  }

  async expiresMarkets(marketids) {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.expiresMarkets(marketids, gasinfo);
    const resp = await tx.wait();
    return resp;
  }

  async destroy() {
    const gasinfo = await getgasinfo();
    const tx = await this.contract.destroy(gasinfo);
    let resp = await tx.wait();
    console.log("destroyed", resp);
  }

  async getOwner() {
    return await this.contract.contowner();
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);
      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      if (name == "MarketCreated") {
        // event MarketCreated(bytes32 marketid, uint created_at);
        let a = par.args;
        o.args = { marketid: a[0], created_at: blocktime_iso(a[1]) };
      } else if (name == "MarketValidated") {
        // event MarketValidated(bytes32 marketid, uint validate_at);
        let a = par.args;
        o.args = { marketid: a[0], validate_at: blocktime_iso(a[1]) };
      } else if (name == "MarketExecuted") {
        // event MarketExecuted(bytes32 marketid, uint executed_at, address from, address to, uint assetTransferQty);
        let a = par.args;
        o.args = {
          marketid: a[0].toLowerCase(),
          executed_at: iso(Number(a[1]) * 1000),
          tok_sender: a[2],
          tok_reciever: a[3],
          asset_qty: Number(a[4]),
          tot_amt_paid_wei: a[5] ? a[5].toString() : null,
          tot_amt_paid: a[5] ? tofeth(a[5]) : null,
        };
        o.args.updated_market = this.parseMarket(a[6]);
      } else if (name == "MarketInvalidated") {
        // event MarketInvalidated(bytes32 marketid, uint invalidate_at);
        let a = par.args;
        o.args = { marketid: a[0], invalidate_at: blocktime_iso(a[1]) };
      } else if (name == "MarketDeleted") {
        // event MarketDeleted(bytes32 marketid, uint delete_at);
        let a = par.args;
        o.args = { marketid: a[0], delete_at: blocktime_iso(a[1]) };
      }
      logrows.push(o);
    }
    return logrows;
  }
}

const name = "dnamarket";
const get_contract_address = (k) => {
  if (!nils(k)) return contractAddress_list[k];
  return contractAddress_list[name];
};

const get_contract = async (ext = {}) => {
  const contractAddress = !nils(ext.contractAddress)
    ? ext.contractAddress
    : get_contract_address(ext.k);
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new Contract(pars);
  return runner;
};

const DNAMarket = {
  class: Contract,
  get_contract: get_contract,
};

export default DNAMarket;
